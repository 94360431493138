import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Colors } from 'core/constants';

const useStyles = makeStyles(() => ({
  root: {
    padding: '2px 2px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    border: `0.3px solid ${Colors.secondary.white}`,
  },
}));

export default function WrapperInput({ children, style, error }) {
  const classes = useStyles();
  return (
    <Paper className={`${error ? 'input-paper-error' : ''} ${classes.root}`} style={{ ...style }}>
      {children}
    </Paper>
  );
}

WrapperInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

WrapperInput.defaultProps = {
  style: {},
  error: null,
};
