/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { fabric } from 'fabric';
import useStorage from 'core/storage/useStorage';
import useDrawBasketCourt from 'components/CourtLayout/Basket/useDrawBasketCourt';
import useDrawText from 'components/DrawText/useDrawText';
import useDrawImage from 'components/Images/useDrawImage';
import useDrawVolleyCourt from 'components/CourtLayout/Volleyball/useDrawVolleyCourt';
import useDrawPickleCourt from 'components/CourtLayout/Pickleball/useDrawPickleCourt';
import { useDispatch } from 'react-redux';
import { projectsActions } from 'modules/projects';
import notifications from 'modules/notifications';
import { removeData } from 'core/utils/session-storage';
import { Colors } from 'core/constants';
import woodColorsOptions from 'core/ui/ColorPicker/woodColorsOptions';
import useWindowSize from '../../core/utils/useWindowSize';
import usePosition from './position';
import useZoom from './useZoom';

const useDraw = ({ projectToOpen }) => {
  const canvasRef = useRef();
  const roomRef = useRef();
  const zoom = useZoom();
  const [canvas, setCanvas] = useState({ width: 0, height: 0, background: '#fff' });
  const dispatch = useDispatch();
  const [scrollRef, setScrollRef] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [modalStart, setModalStart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalEditing, setModalEditing] = useState(false);
  const [modalDuplicate, setModalDuplicate] = useState(false);
  const [modalPreview, setModalPreview] = useState(false);
  const [scaleRatio, setScaleRatio] = useState(6);
  const [repaint, setRepaint] = useState(false);
  const [project, setProject] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const { centerCanvas, centerDirectionInElement } = usePosition();
  const { saveLocal, getLocalData } = useStorage();
  const { drawBasketCourt } = useDrawBasketCourt({
    canvas: canvasRef.current,
    scaleRatio,
  });
  const { drawVolleyCourt } = useDrawVolleyCourt({ canvas: canvasRef.current, scaleRatio });
  const { drawPickleCourt } = useDrawPickleCourt({ canvas: canvasRef.current, scaleRatio });
  const { drawText } = useDrawText();
  const { drawImage } = useDrawImage();
  const [room, setRoom] = useState({ width: 74, length: 130, base: 'synthetic', color: '#fff' });
  const [courts, setCourts] = useState([]);
  const [positions, setPositions] = useState([]);
  const [rotations, setRotations] = useState([]);
  const [scalings, setScalings] = useState([]);
  const [texts, setTexts] = useState([]);
  const [images, setImages] = useState([]);
  const size = useWindowSize();

  useEffect(() => {
    roomRef.current = room;
  }, [room]);

  const clearData = () => {
    setRoom({});
    setCourts([]);
    setImages([]);
    setPositions([]);
    setRotations([]);
    setScalings([]);
    removeData('LOCAL_PROJECT');

    canvasRef.current.getObjects().forEach((ob) => {
      canvasRef.current.remove(ob);
    });
    window.location = '/';
  };

  const duplicate = (view = false) => {
    if (view) {
      setViewOnly(true);
      setModalDuplicate(false);
      return;
    }

    setProject({});
    setViewOnly(false);
    setModalDuplicate(false);
    window.location = '/Draw';
  };

  const previewImage = (callback) => {
    zoom.resetZoom(canvasRef.current);
    canvasRef.current.setWidth(roomRef.current.element.width);
    canvasRef.current.setHeight(roomRef.current.element.height);
    const zoomCanvas = (direction, quality) => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < quality; index++) {
        if (direction === 'in') {
          zoom.zoomIn(canvasRef.current);
        } else {
          zoom.zoomOut(canvasRef.current);
        }
      }
    };
    repaintAll(true);
    setLoading(true);
    setTimeout(() => {
      zoomCanvas('in', 5);
      setTimeout(() => {
        const canvasElement = document.getElementById('canvas');
        const base64Canvas = canvasElement.toDataURL({
          format: 'png',
          quality: 1.0,
        });

        if (callback) {
          callback(base64Canvas);
        } else {
          setModalPreview({ open: true, imageBase64: base64Canvas });
        }
        zoomCanvas('out', 5);
        canvasRef.current.setWidth(size.width);
        canvasRef.current.setHeight(size.height);
        repaintAll();
        setLoading(false);
      }, 1000);
    }, 3000);
  };

  const saveProject = (obj) => {
    previewImage((imageBase64) => {
      const uuid = project.uuid || uuidv4();
      setProject((prev) => ({ uuid, ...prev, ...obj }));

      const dataLocal = getLocalData();

      // const CANV = document.getElementById('canvas');
      const base64Canvas = imageBase64.split(';base64,')[1];
      const data = {
        project: {
          uuid,
          ...obj,
          draw_data: dataLocal,
          image: { base64: base64Canvas },
        },
      };

      const callback = () => {
        dispatch(notifications.success('Project has been saved.'));
        setShowSuccess(true);
      };
      dispatch(projectsActions.postProjectStart(data, callback));
    });
  };

  const saveCourt = (el) => {
    setCourts((prev) => {
      let id = uuidv4();
      let newAr = prev;
      if (el.id) {
        id = el.id;
        newAr = prev.filter((x) => x.id !== el.id);
      }

      const items = [...newAr, { ...el, id }];

      return items.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    });
  };

  const saveText = (el) => {
    setTexts((prev) => {
      let id = uuidv4();
      let newAr = prev;
      if (el.id) {
        id = el.id;
        newAr = prev.filter((x) => x.id !== el.id);
      }

      const items = [...newAr, { ...el, id }];

      return items.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    });
  };

  const saveImage = (el) => {
    setImages((prev) => {
      let id = uuidv4();
      let newAr = prev;
      if (el.id) {
        id = el.id;
        newAr = prev.filter((x) => x.id !== el.id);
      }

      const items = [...newAr, { ...el, id }];

      return items.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    });
  };

  const removeCourt = (el) => {
    setCourts((prev) => {
      const newAr = prev.filter((x) => x.id !== el.id);
      return [...newAr];
    });
  };

  const removeText = (el) => {
    setTexts((prev) => {
      const newAr = prev.filter((x) => x.id !== el.id);
      return [...newAr];
    });
  };

  const removeImage = (el) => {
    setImages((prev) => {
      const newAr = prev.filter((x) => x.id !== el.id);
      return [...newAr];
    });
  };

  const createCourts = (roomObj = null) => {
    courts
      .filter((x) => x.layoutType === 'basketball')
      .forEach((court, index) => {
        const basketCourt = drawBasketCourt({
          canvas: canvasRef.current,
          room: roomObj || roomRef.current,
          court,
          position: positions.find((x) => x.id === court.id),
          rotation: rotations.find((x) => x.id === court.id),
          index,
        });
        if (court.showOutline) {
          drawBasketCourt({
            canvas: canvasRef.current,
            room: roomObj || roomRef.current,
            court,
            basketCourt,
            position: positions.find((x) => x.id === court.id),
            rotation: rotations.find((x) => x.id === court.id),
            outline: true,
            index,
          });
        }
      });

    courts
      .filter((x) => x.layoutType !== 'basketball')
      .forEach((court, index) => {
        if (court.layoutType === 'volleyball') {
          drawVolleyCourt({
            canvas: canvasRef.current,
            room: roomObj || roomRef.current,
            court,
            position: positions.find((x) => x.id === court.id),
            rotation: rotations.find((x) => x.id === court.id),
            index,
          });
        }
        if (court.layoutType === 'pickleball') {
          drawPickleCourt({
            canvas: canvasRef.current,
            room: roomObj || roomRef.current,
            court,
            position: positions.find((x) => x.id === court.id),
            rotation: rotations.find((x) => x.id === court.id),
            index,
          });
        }
      });
  };

  const createTexts = (roomObj = null) => {
    texts.forEach((textElement) => {
      drawText({
        canvas: canvasRef.current,
        textElement,
        room: roomObj || roomRef.current,
        position: positions.find((x) => x.id === textElement.id),
        rotation: rotations.find((x) => x.id === textElement.id),
      });
    });
  };

  const createLogoGym = () => {
    const logo = new fabric.Text('GymFloorDesigns.com', {
      fontFamily: 'HelveticaNeue-Bold',
      fontSize: 20,
      fill: Colors.secondary.solid,
    });
    logo.left = canvasRef.current.width - logo.width - 5;
    logo.top = canvasRef.current.height - logo.height - 3;
    logo.zIndex = 100;
    canvasRef.current.add(logo);
    canvasRef.current.renderAll();
  };

  const createImages = (roomObj = null) => {
    images.forEach((imageElement) => {
      drawImage({
        canvas: canvasRef.current,
        imageElement,
        room: roomObj || roomRef.current,
        position: positions.find((x) => x.id === imageElement.id),
        rotation: rotations.find((x) => x.id === imageElement.id),
        scaling: scalings.find((x) => x.id === imageElement.id),
      });
    });
  };

  const createRoomElement = () =>
    new fabric.Rect({
      width: room.length * scaleRatio,
      height: room.width * scaleRatio,
      fill: room.color,
      selectable: false,
    });

  const createRoom = ({ initial, roomElement }) => {
    if (initial) {
      setRoom((prev) => ({ ...prev, ...roomElement }));
      setRepaint(true);
      return null;
    }
    const drawRoom = createRoomElement();
    centerCanvas(canvasRef.current, drawRoom);

    drawRoom.zIndex = 2;
    canvasRef.current.add(drawRoom);
    setRoom((prev) => ({ id: uuidv4(), ...prev, element: drawRoom }));
    canvasRef.current.renderAll();

    return drawRoom;
  };

  const changeOutline = (target) => {
    if (target.outlineElementId) {
      canvasRef.current.getObjects().forEach((ob) => {
        if (ob.id === target.outlineElementId) {
          ob.left = target.left;
          ob.top = target.top;
        }
      });
    }
  };

  const initCanvas = () =>
    new fabric.Canvas('canvas', {
      height: size.height,
      width: size.width,
      backgroundColor: Colors.secondary.xLight,
      fireRightClick: true, // <-- enable firing of right click events
      fireMiddleClick: true, // <-- enable firing of middle click events
      // stopContextMenu: true, // <--  prevent context menu from showing,
    });

  const setPositionElement = (element) => {
    const roomWidth = roomRef.current.element.width;
    const roomHeight = roomRef.current.element.height;
    const roomLeft = roomRef.current.element.left;
    const roomTop = roomRef.current.element.top;

    setPositions((prev) => [
      ...prev.filter((p) => p.id !== element.id),
      {
        id: element.id,
        left: element.left,
        top: element.top,
        roomWidth,
        roomHeight,
        roomLeft,
        roomTop,
      },
    ]);
  };

  useEffect(() => {
    canvasRef.current = initCanvas();

    // canvasRef.current.on('mouse:down', (event) => {
    //   const RIGHT_CLICK = 2;
    //   if (event.button === RIGHT_CLICK) {

    //   }
    // });

    canvasRef.current.on('object:rotated', (e) => {
      setRotations((prev) => [
        ...prev.filter((p) => p.id !== e.target.id),
        {
          id: e.target.id,
          angle: e.target.angle,
        },
      ]);

      setPositionElement(e.target);
    });

    canvasRef.current.on('object:modified', (e) => {
      // eslint-disable-next-line no-underscore-dangle
      if (e.target._objects && !e.target.id) {
        // eslint-disable-next-line no-underscore-dangle
        e.target._objects.forEach((item) => {
          const left = item.left + e.target.left + e.target.width / 2;
          const top = item.top + e.target.top + e.target.height / 2;

          setPositionElement({ id: item.id, left, top });
          changeOutline(item);
        });
      } else {
        setPositionElement(e.target);
      }

      if (e.target.id) {
        setScalings((prev) => [
          ...prev.filter((p) => p.id !== e.target.id),
          {
            id: e.target.id,
            width: e.target.width * e.target.scaleX,
            height: e.target.height * e.target.scaleY,
          },
        ]);
        changeOutline(e.target);
      }
    });

    setCanvas((prev) => ({
      ...prev,
      width: canvasRef.current.width,
      height: canvasRef.current.height,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const repaintAll = (showLogo = false) => {
    zoom.resetZoom(canvasRef.current);
    canvasRef.current.getObjects().forEach((ob) => {
      canvasRef.current.remove(ob);
    });

    createRoom({});
    setLoading(true);
    setTimeout(() => {
      createCourts();
      createTexts();
      if (showLogo) createLogoGym();
      createImages();
      canvasRef.current.renderAll();

      setTimeout(() => {
        setZIndexElements();
        setZIndexElements();
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setZIndexElements();
        setZIndexElements();
        setLoading(false);
      }, 2800);
      setRepaint(false);
      if (viewOnly) {
        setViewOnlyAllElements();
      }
    }, 1000);
  };

  const setZIndexElements = () => {
    const objs = canvasRef.current.getObjects();
    const objes2 = objs.sort((a, b) => (a.zIndex < b.zIndex ? -1 : Number(a.zIndex > b.zIndex)));

    objes2.forEach((ob) => {
      if (ob.zIndex) {
        canvasRef.current.moveTo(ob, ob.zIndex);
      }
    });
    canvasRef.current.renderAll();
  };

  const setViewOnlyAllElements = () => {
    canvasRef.current.getObjects().forEach((element) => {
      element.set('selectable', false);
      canvasRef.current.renderAll();
    });
  };

  useEffect(() => {
    if (!room.element) {
      return;
    }

    repaintAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courts, texts, images]);

  useEffect(() => {
    if (!repaint) {
      return;
    }

    repaintAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repaint]);

  const clearMaterialFromCourts = () => {
    setCourts((prev) => {
      return prev.map((court) => {
        return {
          ...court,

          baseLineWidth: 0,
          sideLineWidth: 0,
          marginColor: '#000000',

          midCourt: {
            base: null,
            color: null,
            stainColor: null,
          },

          threePoint: {
            base: null,
            color: null,
            stainColor: null,
          },

          topOfKey: {
            base: null,
            color: null,
            stainColor: null,
          },

          bottomOfKey: {
            base: null,
            color: null,
            stainColor: null,
          },

          center: {
            base: null,
            color: null,
            stainColor: null,
          },
        };
      });
    });
  };

  const updateRoom = (current) => {
    if (room.base && room.base !== current.base) {
      clearMaterialFromCourts();
    }
    setRoom((prev) => ({ ...prev, ...current }));
    setRepaint(true);
  };

  useEffect(() => {
    if (!room.element) {
      return;
    }
    if (room.base === 'wood') {
      fabric.util.loadImage(
        woodColorsOptions.find((x) => x.value === room.woodColor).texture,
        (img) => {
          room.element.set(
            'fill',
            new fabric.Pattern({
              source: img,
              repeat: 'repeat',
            }),
          );
          canvasRef.current.renderAll();
        },
      );
    } else {
      room.element.set('fill', room.color);
      canvasRef.current.renderAll();
    }
  }, [room]);

  useEffect(() => {
    if (!room.element) {
      return;
    }
    saveLocal(room, courts, texts, images, positions, scalings, rotations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, courts, texts, images, positions, rotations, scalings]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    let data = null;
    if (projectToOpen && projectToOpen.uuid) {
      setModalDuplicate(true);
      setViewOnly(true);
      setProject(projectToOpen);
      data = projectToOpen.drawData;
    } else {
      data = getLocalData();
      if (data && data.room) {
        setModalEditing(true);
      }
    }

    if (data) {
      setModalStart(false);
      if (!data.room) {
        setModalStart(true);
      }
      setRoom({ ...data.room });
      if (data.courts) {
        setCourts(data.courts || []);
      }
      if (data.texts) {
        setTexts(data.texts || []);
      }
      if (data.images) {
        setImages(data.images || []);
      }
      if (data.positions) {
        setPositions(data.positions || []);
      }
      if (data.scalings) {
        setScalings(data.scalings || []);
      }
      if (data.rotations) {
        setRotations(data.rotations || []);
      }
      createRoom({});
    } else {
      setModalStart(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const centerSelectedElement = (direction) => {
    // centerCanvas(canvasRef.current, canvasRef.current.getActiveObject());
    if (!canvasRef.current.getActiveObject()) {
      return;
    }
    const element = canvasRef.current.getActiveObject();
    centerDirectionInElement({
      elementBase: roomRef.current.element,
      element,
      direction,
    });
    setPositionElement(element);
    changeOutline(element);
    canvasRef.current.renderAll();
  };

  const selectElement = (element) => {
    canvasRef.current.getObjects().forEach((ob) => {
      if (ob.id === element.id) {
        canvasRef.current.setActiveObject(ob);
        canvasRef.current.renderAll();
      }
    });
  };

  return {
    canvas,
    setRoom,
    room,
    scaleRatio,
    setScaleRatio,
    createRoom,
    updateRoom,

    saveCourt,
    removeCourt,
    courts,
    setCourts,

    removeText,
    texts,
    saveText,

    saveImage,
    removeImage,
    images,

    project,
    saveProject,

    showSuccess,
    setShowSuccess,

    modalStart,
    setModalStart,

    clearData,

    modalDuplicate,
    setModalDuplicate,

    duplicate,

    viewOnly,
    setViewOnly,

    modalEditing,
    setModalEditing,

    previewImage,

    loading,
    setLoading,

    zoom: (direction) => zoom.zoomByMouseCoords(canvasRef.current, direction, scrollRef),

    centerSelectedElement,

    modalPreview,
    setModalPreview,

    selectElement,

    setScrollRef,
  };
};

export default useDraw;
