import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import { ToastProvider } from 'react-toast-notifications';
import reportWebVitals from './reportWebVitals';
import App from './App';
import maTheme from './theme';
import store from './store';
import './assets/css/App.css';

const jsx = (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={maTheme}>
      <ThemeProvider theme={maTheme}>
        <Provider store={store}>
          <ToastProvider autoDismiss placement="bottom-left">
            <App />
          </ToastProvider>
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

ReactDOM.render(jsx, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
