/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/require-default-props */
import React from 'react';
import propTypes from 'prop-types';

import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { InputLabel } from '@material-ui/core';
import { Colors } from 'core/constants';

const SelImage = ({ image, setImage }) => {
  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const file = target.files[i];
        setImage({
          url: URL.createObjectURL(file),
          file,
          name: file.name,
          new: true,
        });
      }
    }
  };

  return (
    <Box style={{ position: 'relative', zIndex: 3 }}>
      <InputLabel
        htmlFor="icon-button-file"
        className="hover"
        style={{ background: Colors.secondary.solid, borderRadius: 5, padding: 3 }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography style={{ color: 'white' }} variant="subtitle2" align="center">
            UPLOAD IMAGE
          </Typography>
        </Box>
      </InputLabel>
      <input
        color="primary"
        type="file"
        onClick={(e) => {
          e.target.value = null;
        }}
        onChange={handleCapture}
        id="icon-button-file"
        style={{ display: 'none' }}
        accept=".png,.jpg,.jpeg"
        multiple
      />
      <Box>{image && image.url && <img src={image.url} width={200} />}</Box>
    </Box>
  );
};

SelImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: propTypes.object,
  setImage: propTypes.func,
};

export default SelImage;
