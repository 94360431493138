import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';

import WrapperInput from './WrapperInput';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 20,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
    paddingLeft: 5,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function TextField({
  label,
  name,
  inputRef,
  placeholder,
  error,
  onChange,
  style,
  disabled,
  type,
  onKeyPress,
  pattern,
  step,
  inputProps,
  paperStyle,
  required,
  currency,
  onBlur,
  ...props
}) {
  const classes = useStyles();
  return (
    <div style={{ width: '100%' }}>
      {label !== '' && (
        <Box display="flex">
          <Typography size="label">{label}</Typography>
          {required && (
            <Typography ml={1} color="primary">
              *
            </Typography>
          )}
        </Box>
      )}

      <WrapperInput style={{ ...paperStyle }} error={error}>
        {currency && (
          <Typography className="roboto-regular" ml={1} mr={3} color="secondary" variant="body2">
            $
          </Typography>
        )}
        {onChange === null && (
          <>
            {props.value === null ? (
              <InputBase
                disabled={disabled}
                name={name}
                className={classes.input}
                onBlur={onBlur}
                inputRef={inputRef}
                onKeyPress={onKeyPress}
                placeholder={placeholder}
                inputProps={{ style: { ...style }, autoComplete: 'new-password', ...inputProps }}
                type={type}
                pattern={pattern}
                {...props}
              />
            ) : (
              <InputBase
                disabled={disabled}
                name={name}
                className={classes.input}
                onBlur={onBlur}
                inputRef={inputRef}
                onKeyPress={onKeyPress}
                placeholder={placeholder}
                inputProps={{ style: { ...style }, autoComplete: 'new-password', ...inputProps }}
                type={type}
                pattern={pattern}
                {...props}
              />
            )}
          </>
        )}
        {onChange !== null && (
          <InputBase
            name={name}
            disabled={disabled}
            className={classes.input}
            onKeyPress={onKeyPress}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            onBlur={onBlur}
            inputProps={{ style: { ...style }, step, autoComplete: 'new-password', ...inputProps }}
            type={type}
            pattern={pattern}
            {...props}
          />
        )}
      </WrapperInput>
    </div>
  );
}

TextField.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  onKeyPress: PropTypes.func,
  inputRef: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  paperStyle: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  step: PropTypes.string,
  required: PropTypes.bool,
  currency: PropTypes.bool,
  onBlur: PropTypes.func,
};

TextField.defaultProps = {
  name: null,
  error: null,
  onKeyPress: null,
  inputRef: null,
  placeholder: '',
  onChange: null,
  label: '',
  style: {},
  inputProps: {},
  paperStyle: {},
  type: 'text',
  disabled: false,
  pattern: '',
  step: '',
  required: false,
  currency: false,
  onBlur: () => {},
};
