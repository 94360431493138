import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postProjectError: ['error'],
    postProjectStart: ['data', 'callback'],
    postProjectSuccess: ['project'],

    getProjectsError: ['error'],
    getProjectsStart: ['pagination'],
    getProjectsSuccess: ['projects', 'pagination'],

    getProjectError: ['error'],
    getProjectStart: ['uuid'],
    getProjectSuccess: ['project'],
  },
  { prefix: 'projects/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  project: {},
  error: null,
  loading: false,
  loadingSubmit: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadPostProjectStartReducer = (state) => state.set('loadingSubmit', true);

const postProjectSuccessReducer = (state) =>
  state.merge({
    loadingSubmit: false,
    error: null,
  });

const getProjectsSuccessReducer = (state, action) =>
  state.merge({
    data: action.projects,
    pagination: action.pagination,
    loading: false,
    error: null,
  });

const getProjectSuccessReducer = (state, action) =>
  state.merge({
    project: action.project,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const loadPostProjectErrorReducer = (state, action) =>
  state.merge({
    loadingSubmit: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_PROJECT_START]: loadPostProjectStartReducer,
  [Types.POST_PROJECT_SUCCESS]: postProjectSuccessReducer,
  [Types.POST_PROJECT_ERROR]: loadPostProjectErrorReducer,

  [Types.GET_PROJECTS_START]: loadStartReducer,
  [Types.GET_PROJECTS_SUCCESS]: getProjectsSuccessReducer,
  [Types.GET_PROJECTS_ERROR]: loadErrorReducer,

  [Types.GET_PROJECT_START]: loadStartReducer,
  [Types.GET_PROJECT_SUCCESS]: getProjectSuccessReducer,
  [Types.GET_PROJECT_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const projectsActions = Creators;
export const projectsTypes = Types;
export const projectsSelectors = selectors;
export const projectsSagas = sagas;
