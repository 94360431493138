export const fontFamily = {
  regular: {
    fontFamily: 'HelveticaNeue-Medium',
  },
  normal: {
    fontFamily: 'HelveticaNeue-Medium',
  },
  medium: {
    fontFamily: 'HelveticaNeue-Medium',
  },
  bold: {
    fontFamily: 'HelveticaNeue-Bold',
  },
};

export const sizeFont = {
  xxxLarge: {
    fontSize: 80,
  },
  xxLarge: {
    fontSize: 49,
  },
  xLarge: {
    fontSize: 32,
  },
  large: {
    fontSize: 23,
  },
  medium: {
    fontSize: 17,
  },
  title: {
    fontSize: 19,
  },
  small: {
    fontSize: 14,
  },
  label: {
    fontSize: 14,
  },
  xSmall: {
    fontSize: 12,
  },
  xxSmall: {
    fontSize: 10,
  },
  xxxSmall: {
    fontSize: 8,
  },
};
