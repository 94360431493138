import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import history from './history';
import routes from './routes';

const App = () => {
  return (
    <Suspense fallback={null}>
      <ConnectedRouter history={history}>
        <>{renderRoutes(routes())}</>
      </ConnectedRouter>
    </Suspense>
  );
};

export default App;
