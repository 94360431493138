/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';

import { DrawContext } from 'core/contexts';
import Typography from 'core/ui/Typography';
import TextField from 'core/ui/TextField';
import Radio from 'core/ui/Radio';
import Grid from 'core/ui/Grid';
import ColorPicker from 'core/ui/ColorPicker';
import Box from 'core/ui/Box';
import { ButtonCancel, ButtonSecondary } from 'core/ui/Button';
import FontIcon from 'core/ui/FontIcon';
import { Colors } from 'core/constants';
import { LayoutTypes } from '..';

const FormBasket = ({ current, callback }) => {
  const { saveCourt, courts } = useContext(DrawContext);
  const [showSave, setShowSave] = useState(false);
  const defaultColor = '#ffffff';
  const mainCourt = courts.find((x) => !!x.main);
  const [currentElement, setCurrentElement] = useState({
    layoutType: LayoutTypes.volleyball.value,
    name: 'VOLLEY COURT',
    length: 60,
    width: 30,
    boundaryColor: '#ffffff',

    main: false,

    showOutline: false,
    outlineColor: '#FFEFD6',

    // OPTIONS
    base: null,
    baseColor: defaultColor,
    baseColorWoodColor: defaultColor,

    baseLineWidth: 0,
    sideLineWidth: 0,
    marginColor: '#000000',
  });

  useEffect(() => {
    if (current) {
      setCurrentElement({
        ...current,
      });
    }
  }, [current]);

  useEffect(() => {
    setShowSave(true);
  }, [currentElement]);

  const save = () => {
    setShowSave(false);
    saveCourt(currentElement);
    if (callback) {
      callback();
    }
  };

  const mb = 2;
  return (
    <Box mb={3}>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, name: v }))}
          value={currentElement.name}
          label="Name"
        />
      </Box>
      {(!mainCourt || mainCourt.id === currentElement.id) && (
        <Box mb={mb}>
          <Typography size="small">Main</Typography>
          <Box display="flex" justifyContent="center">
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Radio
                onClick={() => setCurrentElement((prev) => ({ ...prev, main: true }))}
                label="Yes"
                labelPosition="right"
                checked={!!currentElement.main}
              />
            </Box>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Radio
                onClick={() => setCurrentElement((prev) => ({ ...prev, main: false }))}
                label="No"
                labelPosition="right"
                checked={!currentElement.main}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box mb={mb}>
        <Typography size="small">Show Outline</Typography>
        <Box display="flex" justifyContent="center">
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Radio
              onClick={() => setCurrentElement((prev) => ({ ...prev, showOutline: true }))}
              label="Yes"
              labelPosition="right"
              checked={!!currentElement.showOutline}
            />
          </Box>
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Radio
              onClick={() => setCurrentElement((prev) => ({ ...prev, showOutline: false }))}
              label="No"
              labelPosition="right"
              checked={!currentElement.showOutline}
            />
          </Box>
        </Box>
      </Box>
      {currentElement.showOutline && (
        <Box mb={mb}>
          <ColorPicker
            color={currentElement.outlineColor}
            setColor={(v) => setCurrentElement((prev) => ({ ...prev, outlineColor: v }))}
            label="Outline Color"
          />
        </Box>
      )}
      <Box mb={mb}>
        <Typography size="small">Layout</Typography>
        <Box display="flex" justifyContent="center">
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FontIcon iconName="icon-cross-courts" size={25} color={Colors.secondary.solid} />
            <Radio
              onClick={() => setCurrentElement((prev) => ({ ...prev, layoutCross: true }))}
              label="Vertical"
              labelPosition="right"
              checked={!!currentElement.layoutCross}
            />
          </Box>
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FontIcon iconName="icon-court-layout" size={25} color={Colors.secondary.solid} />
            <Radio
              onClick={() => setCurrentElement((prev) => ({ ...prev, layoutCross: false }))}
              label="Horizontal"
              labelPosition="right"
              checked={!currentElement.layoutCross}
            />
          </Box>
        </Box>
      </Box>

      <Grid mb={mb} container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            onChange={(v) => setCurrentElement((prev) => ({ ...prev, length: v }))}
            type="number"
            value={currentElement.length}
            pattern="[0-9]*"
            required
            label="Boundary Length (ft)"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            onChange={(v) => setCurrentElement((prev) => ({ ...prev, width: v }))}
            type="number"
            value={currentElement.width}
            pattern="[0-9]*"
            required
            label="Boundary Width (ft)"
          />
        </Grid>
      </Grid>

      <Box mb={mb}>
        <ColorPicker
          color={currentElement.boundaryColor}
          setColor={(v) => setCurrentElement((prev) => ({ ...prev, boundaryColor: v }))}
          label="Boundary Color"
        />
      </Box>

      {(!currentElement.id || showSave) && (
        <Box mt={5} display="flex">
          <ButtonSecondary fullWidth onClick={() => save()}>
            Save
          </ButtonSecondary>
          &nbsp;
          {!currentElement.id && (
            <ButtonCancel fullWidth onClick={() => callback()}>
              Cancel
            </ButtonCancel>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormBasket;
