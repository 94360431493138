import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import Box from '../Box';
import Typography from '../Typography';

export default function Checkbox({ checked, onClick, label, labelFont, labelPosition, ...props }) {
  return (
    <Box
      alignItems="center"
      display="flex"
      className="hover"
      onClick={onClick}
      flexDirection={labelPosition !== 'top' ? 'row' : 'column'}
    >
      {label !== '' && labelPosition === 'top' && (
        <Box ml={1} display="flex">
          <Typography size="small">{label}</Typography>
        </Box>
      )}
      <MuiCheckbox size="small" style={{ margin: 0, padding: 0 }} checked={checked} {...props} />
      {label !== '' && labelPosition === 'right' && (
        <Box ml={1} display="flex">
          <Typography font={labelFont} size="small">
            {label}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

Checkbox.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  labelFont: PropTypes.string,
  labelPosition: PropTypes.string,
};

Checkbox.defaultProps = {
  onClick: () => {},
  checked: false,
  label: '',
  labelPosition: 'right',
  labelFont: 'regular',
};
