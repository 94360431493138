import React from 'react';

import { Box, Button, Typography as MuiTypography } from '@material-ui/core';
import Typography from 'core/ui/Typography';

import { useHistory } from 'react-router';
import useStylesHome from './style';
import Footer from './Footer';
import Patters from './Patters';

const Home = () => {
  const classes = useStylesHome();
  const history = useHistory();
  return (
    <>
      <Box className={classes.imageHeader}>
        <Box className={classes.headerContainer}>
          <Box
            className={classes.headerContent}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box display="flex" alignItems="center" px={10}>
              <Box flexGrow={1} mr={10}>
                <MuiTypography font="bold" className={classes.title} color="secondary">
                  We are here to help you design your next great gymnasium!
                </MuiTypography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  onClick={() => {
                    history.push('/Draw');
                  }}
                  className={classes.buttonLets}
                  variant="contained"
                >
                  <Typography px={3} font="bold" size="xLarge" noWrap color="secondary">
                    Lets Begin
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box p={5}>
        <Typography font="bold" size="large" color="secondary">
          Presented in Partnership with
        </Typography>
        <Patters />
      </Box>
      <Footer />
    </>
  );
};

export default Home;
