import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Panel from 'components/Panel/index';
import { DrawContext } from 'core/contexts';
import { useParams } from 'react-router';
import { projectsActions, projectsSelectors } from 'modules/projects';
import { useDispatch, useSelector } from 'react-redux';
import Splash from 'core/ui/Splash';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import useDraw from '../../components/Draw';

const PanelDraw = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const loading = useSelector((state) => projectsSelectors.getLoading(state));
  const projectToOpen = useSelector((state) => projectsSelectors.getProject(state));

  useEffect(() => {
    if (uuid) {
      dispatch(projectsActions.getProjectStart(uuid));
    }
  }, []);

  return (
    <Box>
      {((uuid && projectToOpen && projectToOpen.uuid) || !uuid) && <RenderPanel />}

      {loading && !projectToOpen.uuid && <Splash />}
    </Box>
  );
};

const RenderPanel = () => {
  const projectToOpen = useSelector((state) => projectsSelectors.getProject(state));
  const drawHook = useDraw({ projectToOpen });
  const [confirmDialog, setConfirmDialog] = useState({ open: false });
  const [activeMenu, setActiveMenu] = useState(null);

  const removeElement = (el, type) => {
    setConfirmDialog({
      open: true,
      description: `Are you sure you want to remove ${el.name !== undefined ? el.name : type}?`,
      confirm: () => {
        setConfirmDialog({ open: false });
        if (type === 'image') {
          drawHook.removeImage(el);
          return;
        }
        if (type === 'text') {
          drawHook.removeText(el);
          return;
        }
        drawHook.removeCourt(el);
      },
    });
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDialog.open}
        confirm={() => confirmDialog.confirm()}
        cancel={() => setConfirmDialog({ open: false })}
        description={confirmDialog.description}
        labelConfirm="Yes"
        labelCancel="No"
      />
      <DrawContext.Provider value={{ ...drawHook, removeElement, activeMenu, setActiveMenu }}>
        <Panel />
      </DrawContext.Provider>
    </>
  );
};

export default PanelDraw;
