/* eslint-disable no-param-reassign */
const usePosition = () => {
  const centerCanvas = (canvas, element) => {
    const leftCenter = (canvas.width - element.width - element.strokeWidth) / 2;
    element.left = leftCenter;
    const topCenter = (canvas.height - element.height - element.strokeWidth) / 2;
    element.top = topCenter;
  };

  const centerInElement = ({ elementBase, element, cross = false }) => {
    const leftCenter =
      (elementBase.width - (cross ? element.height : element.width) - element.strokeWidth) / 2;

    element.left = elementBase.left + leftCenter;

    const topCenter =
      elementBase.top +
      (elementBase.height - (cross ? element.width : element.height) - element.strokeWidth) / 2;
    element.top = topCenter;
  };

  const centerDirectionInElement = ({ elementBase, element, direction }) => {
    if (direction === 'vertical') {
      const topCenter =
        elementBase.top +
        (elementBase.height -
          (element.cross ? element.width : element.height) -
          element.strokeWidth) /
          2;
      element.top = topCenter;
      return;
    }

    const leftCenter =
      (elementBase.width - (element.cross ? element.height : element.width) - element.strokeWidth) /
      2;
    element.left = elementBase.left + leftCenter;
  };

  const centerTheePoint = ({ elementBase, element, position, baseLineWidth }) => {
    const leftCenter = elementBase.left + baseLineWidth - 0.1;
    element.left = leftCenter;
    if (position === 'right') {
      element.left = elementBase.left + elementBase.width - element.scaleHeightThreePoints - 2;
      element.left -= baseLineWidth;
    }

    const rest = (elementBase.height - element.scaleWidthThreePoints) / 2;
    const topCenter = elementBase.top + rest;
    element.top = topCenter - 1;
  };

  const centerFreethrow = ({
    elementBase,
    element,
    position,
    baseLineWidth,
    bottom = false,
    outline,
  }) => {
    if (bottom) {
      const leftCenter = elementBase.left + baseLineWidth - 1.5;
      element.left = leftCenter;
      if (position === 'right') {
        element.left = elementBase.left + elementBase.width - element.scaleHeightFTBottom + 1.3;
      }

      const rest = (elementBase.height - element.scaleWidthFTBottom) / 2;
      const topCenter = elementBase.top + rest;
      element.top = topCenter;
    } else {
      const leftCenter = elementBase.left + elementBase.scaleHeightFTBottom - (outline ? 2.3 : 2.5);
      element.left = leftCenter;
      if (position === 'right') {
        element.left = elementBase.left - element.scaleHeightFTTop + 1;
      }

      element.top = elementBase.top + (outline ? 4 : 4.5);
    }
  };

  const centerFreethrowHalfCircle = ({ elementBase, element, position }) => {
    if (position === 'right') {
      element.left = elementBase.left + 49.3;
      element.top = elementBase.top + 16.5;
      return;
    }
    element.left = elementBase.left + 20.3;
    element.top = elementBase.top + 16.5;
  };

  function rotate(r = 0, element) {
    let originX;
    let originY;
    switch (r) {
      case 0:
        originX = 'left';
        originY = 'top';
        break;
      case 90:
        originX = 'left';
        originY = 'bottom';
        break;
      case 180:
        originX = 'right';
        originY = 'bottom';
        break;
      case 270:
        originX = 'right';
        originY = 'top';
        break;
      case 360:
        originX = 'left';
        originY = 'top';
        break;
      default:
        originX = 'left';
        originY = 'top';
        break;
    }
    element.set({
      angle: r,
      originX,
      originY,
    });
  }

  const positionElement = ({ element, room, position }) => {
    const diffWidth = position.left - position.roomLeft;
    const diffTop = position.top - position.roomTop;

    if (room && room.element) {
      const diffRoomHeight = position.roomWidth - room.element.width;
      const diffRoomWidth = position.roomHeight - room.element.height;

      const top = room.element.top + diffTop - diffRoomWidth / 2;
      const left = room.element.left + diffWidth - diffRoomHeight / 2;

      element.top = top;
      element.left = left;
    }
  };

  return {
    centerCanvas,
    centerInElement,
    centerTheePoint,
    centerFreethrow,
    positionElement,
    centerFreethrowHalfCircle,
    rotate,
    centerDirectionInElement,
  };
};

export default usePosition;
