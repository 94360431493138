import React from 'react';

import { Box } from '@material-ui/core';
import Typography from 'core/ui/Typography';

const Footer = () => (
  <>
    <Box p={5} display="flex">
      <Typography font="medium" size="medium" color="secondary">
        Copyright Foster Specialty Floors 2021
      </Typography>
      <Typography font="bold" size="medium" color="secondary">
        &nbsp; -&nbsp; Terms of Use
      </Typography>
    </Box>
  </>
);

export default Footer;
