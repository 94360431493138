/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { Collapse, makeStyles } from '@material-ui/core';
import { DrawContext } from 'core/contexts';
import MIcon from 'core/ui/MIcon';
import Divider from 'core/ui/Divider';
import Paper from 'core/ui/Paper';
import useMenuCollapse from 'core/utils/useMenuCollapse';
import { ButtonSelect, ButtonRemoveCourt } from 'core/ui/Button';
import { OpenWith, RemoveCircle } from '@material-ui/icons';
import Form from './Form';

const useStyles = makeStyles(() => ({
  tab: {
    position: 'relative',
  },
  buttonRemove: {
    position: 'absolute',
    top: 12,
    right: 50,
  },
  buttonSelect: {
    position: 'absolute',
    top: 12,
    right: 90,
  },
}));

const ItemList = ({ image, removeElement, selectElement }) => {
  const classes = useStyles();
  const { isOpen, collapseMenu } = useMenuCollapse();

  const open = isOpen(image.id);

  return (
    <Paper mb={1} className={classes.tab}>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        className="hover"
        onClick={() => collapseMenu(image.id, !open)}
      >
        <Box flexGrow={1}>
          <Box>
            {image.name ? image.name.substr(0, 10) : 'Image'}{' '}
            {image && image.url && <img alt="" src={image.image.Location} width={40} />}
          </Box>
        </Box>
        <Box onClick={() => collapseMenu(image.id, !open)}>
          <MIcon icon={open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
        </Box>
      </Box>
      <Box className={classes.buttonSelect}>
        <ButtonSelect onClick={() => selectElement(image)}>
          <OpenWith fontSize="small" />
        </ButtonSelect>
      </Box>

      <Box className={classes.buttonRemove}>
        <ButtonRemoveCourt onClick={() => removeElement(image, 'image')}>
          <RemoveCircle fontSize="small" />
        </ButtonRemoveCourt>
      </Box>
      <Box>
        {open && <Divider mx={3} />}
        <Collapse in={open}>
          <Box p={3}>
            <Form current={image} callback={() => {}} />
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

const ListImages = () => {
  const { images, removeElement, selectElement } = useContext(DrawContext);

  return (
    <Box mb={5}>
      {images &&
        images.map((image, index) => (
          <ItemList
            key={index.toString()}
            image={image}
            removeElement={removeElement}
            selectElement={selectElement}
            index={index}
          />
        ))}
    </Box>
  );
};

export default ListImages;
