/* eslint-disable no-param-reassign */
import { fabric } from 'fabric';
import usePosition from 'components/Draw/position';
import freethrowSvg from 'assets/svg/freethrow-area.svg';
import { v4 as uuidv4 } from 'uuid';
import woodColorsOptions from 'core/ui/ColorPicker/woodColorsOptions.js';
import {
  svgHalfCircle,
  svgThreePointBottom,
  svgThreePointBottomOutline,
  svgThreePointTop,
  svgThreePointTopOutline,
  circleSvg,
  circleOutlineSvg,
  HcSvgPath,
  HcSvgOutline,
  NcaaSvgOutline,
  NcaaSvgPath,
  NbaSvgPath,
  NbaSvgOutline,
} from './svgPaths.js';

const centerCircleWidth = 11.97;
const strokeWidthThreePoint = 2;
const strokeWidthRectLane = 2;
const strokeWidthCenterCircle = 10;
let freethrow = [];

const Z_INDEX_COURT = 20;
const Z_INDEX_MAIN = 10;
const FILL_COURTS_Z_INDEX = 16;
const OUTLINE_Z_INDEX = 100;

fabric.loadSVGFromURL(freethrowSvg, (objects) => {
  freethrow = [...freethrow, ...objects];
});

const courtsConfig = {
  nba: {
    value: 'nba',
    scaleWidthThreePoints: 44,
    scaleHeightThreePoints: 30.6,

    scaleWidthFTBottom: 13.5,
    scaleHeightFTBottom: 19.4,
    scaleWidthFTTop: 13.5,
    scaleHeightFTTop: 6.2,

    scaleWidthFTBottomOutline: 13.3,
    scaleHeightFTBottomOutline: 19.4,
    scaleWidthFTTopOutline: 13.65,
    scaleHeightFTTopOutline: 6.2,

    svgOutline: NbaSvgOutline,
    svgPath: NbaSvgPath,
  },
  ncaa: {
    value: 'ncaa',
    scaleWidthThreePoints: 43.33,
    scaleHeightThreePoints: 27.9,

    svgOutline: NcaaSvgOutline,
    svgPath: NcaaSvgPath,
  },
  highschool: {
    value: 'highschool',
    scaleWidthThreePoints: 40,
    scaleHeightThreePoints: 24.8,

    svgOutline: HcSvgOutline,
    svgPath: HcSvgPath,
  },
};

const strokeWidthDefault = 2;

const useDrawBasketCourt = ({ scaleRatio }) => {
  let courtGroup = {};
  const {
    rotate,
    centerInElement,
    centerCanvas,
    centerTheePoint,
    centerFreethrow,
    centerFreethrowHalfCircle,
    positionElement,
  } = usePosition();

  const newLine = ({ x1, y1, x2, y2, strokeWidth, strokeColor = '#000' }) => {
    const line = new fabric.Line([x1, y1, x2, y2], {
      stroke: strokeColor,
      strokeWidth: Number(strokeWidth),
    });
    line.selectable = false;

    return line;
  };

  const newRectLine = ({ court, outline, height, width }) => {
    const line = new fabric.Rect({
      width,
      height,
      fill: outline ? 'transparent' : court.boundaryColor,
      stroke: outline ? court.outlineColor : 'transparent',
      strokeWidth: 0.1,
    });

    return line;
  };

  const drawRectangleCanvas = ({
    canvas,
    width,
    height,
    strokeBaseLine,
    strokeBorder,
    align = null,
    court,
    outline,
  }) => {
    height += strokeBorder * 2;
    width += strokeBaseLine * 2;
    const hEnd = width;

    const topCenter = (canvas.height - height) / 2;
    const leftCenter = (canvas.width - width) / 2;

    const lineTop = newRectLine({ court, outline, height: strokeWidthDefault, width: hEnd });
    if (align === 'center') {
      lineTop.top = topCenter;
      lineTop.left = leftCenter;
    }

    const lineBottom = newRectLine({ court, outline, height: strokeWidthDefault, width: hEnd });
    if (align === 'center') {
      lineBottom.top = canvas.height - strokeBorder - topCenter;
      lineBottom.left = leftCenter;
    }

    const lineLeft = newRectLine({
      court,
      outline,
      height: height - strokeBaseLine,
      width: strokeWidthDefault,
    });
    if (align === 'center') {
      lineLeft.top = topCenter;
      lineLeft.left = leftCenter;
    }

    const lineRight = newRectLine({
      court,
      outline,
      height: height - strokeBaseLine,
      width: strokeWidthDefault,
    });
    if (align === 'center') {
      lineRight.top = topCenter;
      lineRight.left = canvas.width - strokeBaseLine - leftCenter;
    }

    // DRAW BACKGROUND
    const rec = new fabric.Rect({});
    rec.width = width - 1;
    rec.height = height - 1;
    rec.left = lineLeft.left;
    rec.top = lineTop.top;

    centerCanvas(canvas, rec);

    if (court.midCourt && court.midCourt.base) {
      if (court.midCourt.base === 'stain') {
        fabric.util.loadImage(
          woodColorsOptions.find((x) => x.value === court.midCourt.stainColor).texture,
          (img) => {
            rec.set(
              'fill',
              new fabric.Pattern({
                source: img,
                repeat: 'repeat',
              }),
            );
          },
        );
      } else {
        rec.set('fill', court.midCourt.color);
      }
    } else {
      rec.set('fill', 'transparent');
    }

    const arrayGroup = [lineLeft, lineTop, lineBottom, lineRight];
    if (court.main) {
      rec.selectable = false;
      rec.zIndex = Z_INDEX_MAIN;
      canvas.add(rec);
    } else {
      arrayGroup.push(rec);
    }

    courtGroup = new fabric.Group(arrayGroup, {});

    return courtGroup;
  };

  const genTpline = ({ config, outline, court }) => {
    const pathTpLine = new fabric.Path(config.svgOutline);
    if (outline) {
      pathTpLine.fill = 'transparent';
      pathTpLine.strokeWidth = outline ? 0.2 : strokeWidthThreePoint;
      pathTpLine.stroke = court.outlineColor;
    } else {
      pathTpLine.fill = court[`${config.value}BoundaryColor`];
    }
    pathTpLine.scaleWidthThreePoints = config.scaleWidthThreePoints * scaleRatio;
    pathTpLine.scaleHeightThreePoints = config.scaleHeightThreePoints * scaleRatio;
    pathTpLine.scaleToWidth(config.scaleWidthThreePoints * scaleRatio);
    rotate(270, pathTpLine);
    return pathTpLine;
  };

  const genRectLaneBottom = ({ config, outline, court }) => {
    const PathFreethrowBottom = new fabric.Path(
      outline ? svgThreePointBottomOutline : svgThreePointBottom,
    );
    PathFreethrowBottom.strokeWidth = outline ? 0.2 : strokeWidthRectLane;
    PathFreethrowBottom.stroke = outline ? court.outlineColor : court.boundaryColor;
    PathFreethrowBottom.fill = 'transparent';
    PathFreethrowBottom.scaleWidthFTBottom =
      (outline ? config.scaleWidthFTBottomOutline : config.scaleWidthFTBottom) * scaleRatio;
    PathFreethrowBottom.scaleHeightFTBottom =
      (outline ? config.scaleHeightFTBottomOutline : config.scaleHeightFTBottom) * scaleRatio;
    PathFreethrowBottom.scaleToWidth(config.scaleWidthFTBottom * scaleRatio);
    rotate(270, PathFreethrowBottom);

    return PathFreethrowBottom;
  };

  const genHalfCircleLaneBottom = () => {
    const HalfCircle = new fabric.Path(svgHalfCircle);
    const size = 8;
    HalfCircle.scaleWidthFTBottom = size * scaleRatio;
    HalfCircle.scaleHeightFTBottom = size * scaleRatio;
    HalfCircle.scaleToWidth(size * scaleRatio);
    HalfCircle.fill = 'transparent';
    rotate(270, HalfCircle);
    return HalfCircle;
  };

  const genRectLaneTop = ({ config, court, outline }) => {
    const PathFreethrowTop = new fabric.Path(outline ? svgThreePointTopOutline : svgThreePointTop);
    PathFreethrowTop.stroke = outline ? court.outlineColor : court.boundaryColor;
    PathFreethrowTop.strokeWidth = outline ? 0.2 : strokeWidthRectLane;
    PathFreethrowTop.scaleWidthFTTop =
      (outline ? config.scaleWidthFTTopOutline : config.scaleWidthFTTop) * scaleRatio;
    PathFreethrowTop.scaleHeightFTTop =
      (outline ? config.scaleHeightFTTopOutline : config.scaleHeightFTTop) * scaleRatio;
    PathFreethrowTop.scaleToWidth(
      (Number(outline ? config.scaleWidthFTTopOutline : config.scaleWidthFTTop) - 1.5) * scaleRatio,
    );
    PathFreethrowTop.fill = 'transparent';
    rotate(270, PathFreethrowTop);

    return PathFreethrowTop;
  };

  const genHcCircle = ({ court, fill = '', outline }) => {
    const width = centerCircleWidth * scaleRatio;
    const element = new fabric.Path(outline ? circleOutlineSvg : circleSvg);
    element.strokeWidth = outline ? 0.4 : strokeWidthCenterCircle;
    element.scaleToWidth(width);
    element.name = 'genHcCircle';
    element.fill = outline ? 'transparent' : fill;
    element.stroke = outline ? court.outlineColor : court.boundaryColor;

    rotate(270, element);

    if (outline) {
      element.stroke = court.outlineColor;
    }
    const group = new fabric.Group([element], {});

    return group;
  };

  const getTexture = (value) => {
    const woodTexture = woodColorsOptions.find((x) => x.value === value);
    return woodTexture ? woodTexture.textureV : null;
  };

  const applyTexture = ({ element, texture }) => {
    fabric.util.loadImage(getTexture(texture), (img) => {
      const pattern = new fabric.Pattern({
        source: img,
        repeat: 'repeat',
      });
      console.log('patternTrasnsform', pattern.patternTrasnsform);
      element.set('fill', pattern);
    });
  };

  const drawArea3PointsFill = ({ court, tpLine, canvas, configCourt, position, room }) => {
    if ((court.nba || court.ncaa) && configCourt.value === 'highschool') {
      return;
    }
    if (court.nba && configCourt.value === 'ncaa') {
      return;
    }

    const tpLineFill = new fabric.Path(configCourt.svgPath);
    tpLineFill.scaleWidthThreePoints = configCourt.scaleWidthThreePoints * scaleRatio;
    tpLineFill.scaleHeightThreePoints = configCourt.scaleHeightThreePoints * scaleRatio;
    tpLineFill.scaleToWidth(configCourt.scaleWidthThreePoints * scaleRatio);
    rotate(270, tpLineFill);
    if (position === 'right') {
      rotate(90, tpLineFill);
    }
    tpLineFill.left = tpLine.left;
    tpLineFill.top = tpLine.top;
    tpLineFill.selectable = false;
    tpLineFill.fill = '';
    const courtConfig = court.threePoint || {};
    if (room.base === 'wood') {
      applyTexture({
        element: tpLineFill,
        texture: courtConfig.base === 'stain' ? courtConfig.stainColor : room.woodColor,
      });
    } else {
      tpLineFill.fill = courtConfig.base === 'synthetic' ? courtConfig.color : room.color;
    }

    tpLineFill.tpLineFill = true;
    tpLineFill.zIndex = FILL_COURTS_Z_INDEX;
    canvas.add(tpLineFill);
  };

  const drawFill3PointRect = ({ court, rectBottom, rectTop, canvas, room }) => {
    let fillTop = '';
    let fillBottom = '';

    if (court.topOfKey) {
      if (court.topOfKey.base === 'stain') {
        fillTop = court.topOfKey.stainColor;
      }
      if (court.topOfKey.base === 'synthetic') {
        fillTop = court.topOfKey.color;
      }
    }

    if (court.bottomOfKey) {
      if (court.bottomOfKey.base === 'stain') {
        fillBottom = court.bottomOfKey.stainColor;
      }
      if (court.bottomOfKey.base === 'synthetic') {
        fillBottom = court.bottomOfKey.color;
      }
    }

    if (room.base === 'wood') {
      const rectTopFill = fabric.util.object.clone(rectTop);
      applyTexture({
        element: rectTopFill,
        texture: fillTop || room.woodColor,
      });
      rectTopFill.selectable = false;
      rectTopFill.zIndex = FILL_COURTS_Z_INDEX + 1;
      canvas.add(rectTopFill);

      const rectBottomFill = fabric.util.object.clone(rectBottom);
      applyTexture({ element: rectBottomFill, texture: fillBottom || room.woodColor });
      rectBottomFill.selectable = false;
      rectBottomFill.zIndex = FILL_COURTS_Z_INDEX + 1;
      canvas.add(rectBottomFill);
    }

    if (room.base === 'synthetic') {
      const rectTopFill = fabric.util.object.clone(rectTop);
      rectTopFill.fill = fillTop || room.color;
      rectTopFill.zIndex = FILL_COURTS_Z_INDEX;
      rectTopFill.selectable = false;
      canvas.add(rectTopFill);

      const rectBottomFill = fabric.util.object.clone(rectBottom);
      rectBottomFill.fill = fillBottom || room.color;
      rectBottomFill.selectable = false;
      rectBottomFill.zIndex = FILL_COURTS_Z_INDEX;
      canvas.add(rectBottomFill);
    }
  };

  const drawArea3Points = ({ position, court, canvas, outline, room }) => {
    const courtAreas = [];
    const areas = [];
    Object.keys(courtsConfig).forEach((c) => {
      if (court[c]) {
        const conf = courtsConfig[c];
        const tpLine = genTpline({ config: conf, court, outline });
        if (position === 'right') {
          rotate(90, tpLine);
        }
        centerTheePoint({
          elementBase: courtGroup,
          element: tpLine,
          position,
          baseLineWidth: strokeWidthDefault,
          outline,
        });

        tpLine.originalSize = { width: tpLine.scaleX, height: tpLine.height };
        if (court.main && !outline) {
          drawArea3PointsFill({ tpLine, canvas, court, configCourt: conf, position, room });
        }
        courtAreas.push(tpLine);
      }
    });

    const tpLineGroup = new fabric.Group(courtAreas);

    const halfCircleBottom = genHalfCircleLaneBottom();
    halfCircleBottom.stroke = court.boundaryColor;
    halfCircleBottom.strokeWidth = strokeWidthRectLane;
    rotate(270, halfCircleBottom);

    const rectBottom = genRectLaneBottom({ config: courtsConfig.nba, court, outline });

    centerFreethrow({
      elementBase: courtGroup,
      element: rectBottom,
      position,
      baseLineWidth: strokeWidthDefault,
      bottom: true,
    });
    centerFreethrowHalfCircle({
      elementBase: rectBottom,
      element: halfCircleBottom,
      position,
      baseLineWidth: strokeWidthDefault,
      bottom: true,
    });
    if (position === 'left') {
      rotate(90, rectBottom);
      rotate(90, halfCircleBottom);
    }

    const rectTop = genRectLaneTop({ config: courtsConfig.nba, court, outline });

    centerFreethrow({
      elementBase: rectBottom,
      element: rectTop,
      position,
      baseLineWidth: strokeWidthDefault,
      bottom: false,
      outline,
    });
    if (position === 'left') {
      rotate(90, rectTop);
    }

    areas.push(tpLineGroup);
    areas.push(rectBottom);
    areas.push(rectTop);
    if (!outline && (court.ncaa === true || court.nba === true)) {
      areas.push(halfCircleBottom);
    }

    if (!outline && court.main) {
      drawFill3PointRect({ court, canvas, rectBottom, rectTop, room });
    }

    return new fabric.Group(areas, {});
  };

  const drawCourts = ({ canvas, court, outline, room }) => {
    const lineCenter = new fabric.Rect({
      width: strokeWidthDefault,
      height: courtGroup.height,
      fill: outline ? 'transparent' : court.boundaryColor,
      stroke: outline ? court.outlineColor : 'transparent',
      strokeWidth: 0.1,
    });
    centerInElement({ elementBase: courtGroup, element: lineCenter, cross: false });
    lineCenter.top = courtGroup.top;

    const left3Points = drawArea3Points({
      canvas,
      position: 'left',
      court,
      outline,
      room,
    });
    const rightPoints = drawArea3Points({
      canvas,
      position: 'right',
      court,
      outline,
      room,
    });

    const ftCircleCenter = genHcCircle({ court, outline, room, canvas });
    centerInElement({ elementBase: courtGroup, element: ftCircleCenter, cross: true });

    let fill = '';
    if (court.center) {
      if (court.center.base === 'stain') {
        fill = court.center.stainColor;
      }
      if (court.center.base === 'synthetic') {
        fill = court.center.color;
      }
    }
    if (!outline && court.main) {
      // eslint-disable-next-line no-underscore-dangle
      const ftCircleCenterFill = fabric.util.object.clone(ftCircleCenter._objects[0]);
      ftCircleCenterFill.selectable = false;
      if (room.base === 'wood') {
        applyTexture({ element: ftCircleCenterFill, texture: fill || room.woodColor });
      } else {
        ftCircleCenterFill.fill = fill || room.color;
      }
      ftCircleCenterFill.zIndex = FILL_COURTS_Z_INDEX;
      canvas.add(ftCircleCenterFill);
      canvas.renderAll();
    }

    return new fabric.Group([left3Points, rightPoints, ftCircleCenter, lineCenter], {});
  };

  const drawBordersCourt = ({ court, rectCourt }) => {
    const lineBorderTop = newLine({
      x1: 0,
      y1: 0,
      x2: rectCourt.width,
      y2: 0,
      strokeWidth: court.sideLineWidth * scaleRatio,
      strokeColor: court.marginColor,
    });

    lineBorderTop.top = rectCourt.top - lineBorderTop.strokeWidth;
    lineBorderTop.left = rectCourt.left;

    const lineBorderBottom = fabric.util.object.clone(lineBorderTop);
    lineBorderBottom.top = rectCourt.top + rectCourt.height;

    const lineBorderLeft = newLine({
      x1: 0,
      y1: 0,
      x2: 0,
      y2: rectCourt.height + Number(lineBorderTop.strokeWidth) * 2,
      strokeWidth: court.baseLineWidth * scaleRatio,
      strokeColor: court.marginColor,
    });
    lineBorderLeft.top = rectCourt.top - Number(lineBorderTop.strokeWidth);
    lineBorderLeft.left = rectCourt.left - Number(lineBorderLeft.strokeWidth) + 0.5;

    const lineBorderRight = fabric.util.object.clone(lineBorderLeft);
    lineBorderRight.left = lineBorderRight.left + rectCourt.width + lineBorderLeft.strokeWidth - 1;
    return new fabric.Group([lineBorderTop, lineBorderBottom, lineBorderLeft, lineBorderRight], {});
  };

  const drawBasketCourt = ({ canvas, court, position, outline, basketCourt, room }) => {
    const arrayGroupCourt = [];
    const rectCourt = drawRectangleCanvas({
      width: Number(court.length) * scaleRatio,
      height: Number(court.width) * scaleRatio,
      strokeBaseLine: strokeWidthDefault,
      strokeBorder: strokeWidthDefault,
      align: 'center',
      canvas,
      court,
      outline,
    });
    arrayGroupCourt.push(rectCourt);
    const courtLines = drawCourts({
      canvas,
      court,
      outline,
      room,
    });
    arrayGroupCourt.push(courtLines);
    if (!outline && (court.sideLineWidth > 0 || court.baseLineWidth > 0)) {
      const groupBorder = drawBordersCourt({ court, rectCourt });
      groupBorder.zIndex = court.breakBorder ? 5 : 40;
      if (court.main) {
        groupBorder.selectable = false;
        canvas.add(groupBorder);
      } else {
        arrayGroupCourt.push(groupBorder);
      }
    }

    const groupCourt = new fabric.Group(arrayGroupCourt, {});
    groupCourt.id = court.id;
    groupCourt.lockRotation = true;
    groupCourt.lockScalingX = true;
    groupCourt.lockScalingY = true;
    if (court.layoutCross) {
      groupCourt.cross = true;
      rotate(90, groupCourt);
    }

    if (position) {
      positionElement({ element: groupCourt, position });
    }

    if (court.main) {
      groupCourt.selectable = false;
      centerCanvas(canvas, groupCourt);
    }

    if (outline) {
      groupCourt.id = uuidv4();
      basketCourt.outlineElementId = groupCourt.id;
      groupCourt.selectable = false;
    }

    canvas.add(groupCourt);
    if (court.main && !outline) {
      groupCourt.zIndex = 25;
    }
    if (!court.main) {
      if (position) {
        positionElement({ element: groupCourt, room, position });
      }
      groupCourt.zIndex = Z_INDEX_COURT;
    }
    if (outline) {
      groupCourt.zIndex = OUTLINE_Z_INDEX;
    }
    canvas.renderAll();

    return groupCourt;
  };

  return {
    drawBasketCourt,
    applyTexture,
  };
};

export default useDrawBasketCourt;
