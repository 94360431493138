/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';
import Radio from 'core/ui/Radio';
import ColorPicker from 'core/ui/ColorPicker';
import WoodColors from 'core/ui/ColorPicker/WoodColors';

const OptionAreaColor = ({ setOption, option, object, baseRoom }) => {
  const mb = 2;
  const set = (field, value) => {
    setOption((prev) => ({ ...prev, [object]: { ...prev[object], [field]: value } }));
  };

  return (
    <Box>
      <Box mb={mb} display="flex">
        <Radio
          onClick={() => set('base', null)}
          label="None"
          labelPosition="right"
          checked={!option.base}
        />
        {baseRoom === 'synthetic' && (
          <Box mx={2}>
            <Radio
              onClick={() => set('base', 'synthetic')}
              label="Color"
              labelPosition="right"
              checked={option.base === 'synthetic'}
            />
          </Box>
        )}

        {baseRoom === 'wood' && (
          <Box mx={2}>
            <Radio
              onClick={() => set('base', 'stain')}
              label="Stain"
              labelPosition="right"
              checked={option.base === 'stain'}
            />
          </Box>
        )}
      </Box>
      {option.base === 'synthetic' && (
        <Box mb={mb}>
          <ColorPicker
            color={option.color}
            setColor={(color) => set('color', color)}
            label="Synthetic Color"
          />
        </Box>
      )}
      {option.base === 'stain' && (
        <Box>
          <WoodColors
            hideNone
            color={option.stainColor}
            setColor={(color) => set('stainColor', color)}
          />
        </Box>
      )}
    </Box>
  );
};

export default OptionAreaColor;
