import React, { useContext, useEffect, useState } from 'react';
import { DrawContext } from 'core/contexts';
import Typography from 'core/ui/Typography';
import TextField from 'core/ui/TextField';
import Radio from 'core/ui/Radio';
import Grid from 'core/ui/Grid';
import ColorPicker from 'core/ui/ColorPicker';
import Box from 'core/ui/Box';
import { ButtonSecondary } from 'core/ui/Button';
import WoodColors from 'core/ui/ColorPicker/WoodColors';
import ConfirmDialog from 'core/ui/ConfirmDialog';

const CourtLayoutForm = () => {
  const [current, setCurrent] = useState({
    base: 'synthetic',
  });
  const { createRoom, updateRoom, room, courts } = useContext(DrawContext);
  const [confirmDialog, setConfirmDialog] = useState({ open: false });
  useEffect(() => {
    if (room) {
      const { length, base, color, width, woodColor } = room;
      setCurrent({ length, base, color, width, woodColor });
    }
  }, [room]);

  const mb = 2;

  const showAlertRoomChange = () => {
    const confirm = () => {
      setConfirmDialog({
        open: false,
      });
      if (!room.element) {
        createRoom({ initial: true, roomElement: current });
      } else {
        updateRoom(current);
      }
    };

    if (courts && courts.length > 0 && room.base && room.base !== current.base) {
      setConfirmDialog({
        open: true,
        confirm,
      });
    } else {
      confirm();
    }
  };

  const save = () => {
    showAlertRoomChange();
  };

  return (
    <Box px={3}>
      <Typography mb={mb} font="regular" size="xSmall">
        Define the size of the floor area that will contain all your court layouts.
      </Typography>

      <ConfirmDialog
        open={confirmDialog.open}
        confirm={() => confirmDialog.confirm()}
        cancel={() => setConfirmDialog({ open: false })}
        description="If you change room material, all background court settings will be cleared."
        labelConfirm="Confirm"
        labelCancel="Cancel"
      />

      <Grid mb={mb} container spacing={5}>
        <Grid item xs={12} md={6}>
          <TextField
            onChange={(v) => setCurrent((prev) => ({ ...prev, length: v }))}
            type="number"
            value={current.length || ''}
            pattern="[0-9]*"
            required
            label="Length (ft)"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            onChange={(v) => setCurrent((prev) => ({ ...prev, width: v }))}
            type="number"
            value={current.width || ''}
            pattern="[0-9]*"
            required
            label="Width (ft)"
          />
        </Grid>
      </Grid>

      <Box mb={mb}>
        <Typography size="small">Base Material</Typography>
        <Radio
          onClick={() => setCurrent((prev) => ({ ...prev, base: 'wood' }))}
          label="Wood"
          labelPosition="right"
          checked={current.base === 'wood'}
        />
        <Radio
          onClick={() => setCurrent((prev) => ({ ...prev, base: 'synthetic' }))}
          label="Synthetic"
          labelPosition="right"
          checked={current.base === 'synthetic'}
        />
      </Box>

      {current.base === 'synthetic' && (
        <Box mb={mb}>
          <ColorPicker
            color={current.color}
            setColor={(v) => setCurrent((prev) => ({ ...prev, base: 'synthetic', color: v }))}
            label="Synthetic Color"
            room
          />
        </Box>
      )}

      {current.base === 'wood' && (
        <Box mb={mb}>
          <WoodColors
            color={current.woodColor}
            setColor={(v) => setCurrent((prev) => ({ ...prev, base: 'wood', woodColor: v }))}
            label="Stain Color"
          />
        </Box>
      )}

      <Box mt={5}>
        <ButtonSecondary onClick={() => save()} fullWidth>
          Save
        </ButtonSecondary>
      </Box>
    </Box>
  );
};

export default CourtLayoutForm;
