/* eslint-disable react/prop-types */
/* eslint-disable no-self-assign */
import React, { useRef, useState } from 'react';
import {
  Toolbar,
  AppBar,
  useScrollTrigger,
  CssBaseline,
  Box,
  Menu,
  Button,
  MenuItem,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'core/constants';
import Typography from 'core/ui/Typography';
import { persistData, retrieveData } from 'core/utils/session-storage';
import SelFileProject from 'core/ui/SelFileProject';

function ElevationScroll({ title, ...props }) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default ({ currentRoute, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    appBar: {
      background: Colors.secondary.solid,
      height: '45px',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        height: '45px',
      },
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: 10,
    },
    content: {
      flexGrow: 1,
    },
    logo: {
      height: 44,
      width: 50,
      [theme.breakpoints.down('sm')]: {
        height: 30,
        width: 36,
      },
    },
    title: {
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
  }));
  const [openMenu, setOpenMenu] = useState(false);
  const anchorEl = useRef();
  const classes = useStyles();

  const importFile = (file) => {
    if (file && file.text) {
      persistData('LOCAL_PROJECT', file.text);
      window.location = window.location;
    }
  };

  const saveFile = () => {
    const dataLocal = retrieveData('LOCAL_PROJECT');

    const element = document.createElement('a');
    const file = new Blob([dataLocal], {
      type: 'text/plain;charset=utf-8',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'foster-project.fosterp';
    document.body.appendChild(element);
    element.click();
  };

  const newProject = () => {
    window.location = '/Draw';
  };

  const exit = () => {
    window.location = '/';
  };

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar className={classes.toolbar}>
            <Box>
              {currentRoute.path === '/Draw' && (
                <>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => setOpenMenu(true)}
                    ref={anchorEl}
                  >
                    <Typography style={{ color: 'white' }}>Menu</Typography>
                  </Button>
                  <Menu
                    anchorEl={anchorEl.current}
                    keepMounted
                    open={openMenu}
                    onClose={() => setOpenMenu(false)}
                  >
                    <MenuItem onClick={() => newProject()}>
                      <Typography font="bold" size="medium" color="secondary">
                        New Project
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => importFile()}>
                      <SelFileProject setFile={importFile} />
                    </MenuItem>
                    <MenuItem onClick={() => saveFile()}>
                      <Typography font="bold" size="medium" color="secondary">
                        Save Project
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => exit()}>
                      <Typography font="bold" size="medium" color="secondary">
                        Exit
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
            <Box flexGrow={1} />
            <Link href="/" className="hover" color="inherit">
              <Typography align="right" size="large" font="bold" color="inherit">
                GymFloorDesigns.com
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};
