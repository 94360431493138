import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from 'core/constants';
import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import props from './props';
import shadows from './shadows';

export default createMuiTheme({
  spacing: 4,
  breakpoints,
  overrides,
  props,
  typography,
  shadows,
  palette: {
    primary: {
      main: Colors.primary.solid,
      contrastText: '#FFF',
    },
    secondary: {
      main: Colors.secondary.solid,
      contrastText: '#FFF',
    },
  },
});
