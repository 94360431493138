import { menuActions, menuSelectors } from 'modules/menu';
import { useDispatch, useSelector } from 'react-redux';

export default () => {
  const dispatch = useDispatch();
  const collapseState = useSelector((state) => menuSelectors.getCollapseMenuState(state));
  const isOpen = (id) => {
    return !!collapseState[id];
  };

  const collapseMenu = (id, value) => {
    dispatch(menuActions.collapseMenu(id, value));
  };

  const toggleMenu = (id) => {
    dispatch(menuActions.collapseMenu(id, !isOpen(id)));
  };

  return { isOpen, collapseMenu, toggleMenu };
};
