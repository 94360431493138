import React from 'react';

import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { v4 as uuidv4 } from 'uuid';
import TabCourt from './TabCourt';
import FormBasket from './Basket/Form';
import BasketCourtList from './Basket/List';

export const OptionsTypes = {
  basketball: {
    id: uuidv4(),
    value: 'basketball',
    label: 'Basketball',
    form: (props) => <FormBasket {...props} />,
    list: (props) => <BasketCourtList {...props} />,
  },
  // volleyball: {
  //   value: 'volleyball',
  //   label: 'Volleyball',
  //   form: (props) => <FormBasket {...props} />,
  //   list: (props) => <BasketCourtList {...props} />,
  // },
  // pickleball: {
  //   value: 'pickleball',
  //   label: 'Pickleball',
  //   form: (props) => <FormBasket {...props} />,
  //   list: (props) => <BasketCourtList {...props} />,
  // },
};

const Courts = () => {
  const mb = 2;
  return (
    <Box mb={10}>
      <Typography mx={3} mb={mb} font="regular" size="xSmall">
        Select your main court material, colors or stains.
      </Typography>

      {Object.keys(OptionsTypes).map((type, index) => (
        <TabCourt key={index.toString()} tab={OptionsTypes[type]} />
      ))}
    </Box>
  );
};

export default Courts;
