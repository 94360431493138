/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Button, Paper } from '@material-ui/core';
import { DrawContext } from 'core/contexts';
import Divider from 'core/ui/Divider';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import FontIcon from 'core/ui/FontIcon';
import { Colors } from 'core/constants';
import RoomSizeForm from 'components/RoomSize/Form';
import CourtLayout from 'components/CourtLayout/index';
import CourtOptions from 'components/CourtOptions/index';
import DrawText from 'components/DrawText/index';
import clsx from 'clsx';
import Images from 'components/Images';
import Finish from 'components/Finish';
import useStylesLeftBar from './style';

const MenuOptions = {
  RoomSize: {
    title: 'Room Size',
    icon: 'icon-room-size',
    form: <RoomSizeForm />,
  },
  CourtLayout: {
    title: 'Court Layout',
    icon: 'icon-court-layout',
    form: <CourtLayout />,
  },

  CourtOptions: {
    title: 'Court Options',
    icon: 'icon-court-options',
    form: <CourtOptions />,
  },
  Text: {
    title: 'Text',
    icon: 'icon-format-text',
    form: <DrawText />,
  },
  LogoUpload: {
    title: 'Logo Upload',
    icon: 'icon-cloud-upload',
    form: <Images />,
  },
  Preview: {
    title: 'Preview',
    icon: 'icon-image',
    onClick: (drawContext) => {
      drawContext.previewImage();
    },
  },
  Finish: {
    title: 'FINISH',
    icon: null,
    form: <Finish />,
  },
};

const RightBar = () => {
  const classes = useStylesLeftBar();
  const drawContext = useContext(DrawContext);

  // eslint-disable-next-line react/prop-types
  const ButtonMenu = ({ menu }) => {
    const active = drawContext.activeMenu === menu;
    return (
      <Button
        className={clsx(classes.buttonRightBar, active ? classes.activeButton : null)}
        variant="contained"
        onClick={() => (menu.onClick ? menu.onClick(drawContext) : drawContext.setActiveMenu(menu))}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            ml={2}
            style={{ width: 30 }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {menu.icon && (
              <FontIcon
                iconName={menu.icon}
                size={25}
                color={active ? Colors.secondary.white : Colors.secondary.solid}
              />
            )}
          </Box>
          <Box
            flexGrow={1}
            style={{ width: 110 }}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography
              ml={3}
              font="bold"
              size="small"
              noWrap
              style={{ color: active ? Colors.secondary.white : Colors.secondary.solid }}
            >
              {menu.title}
            </Typography>
          </Box>
        </Box>
      </Button>
    );
  };
  return (
    <Paper elevation={1} className={classes.root}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        {Object.keys(MenuOptions).map((item, i) => (
          <React.Fragment key={i.toString()}>
            <ButtonMenu menu={MenuOptions[item]} />
            <Divider variant="fullWidth" />
          </React.Fragment>
        ))}
      </Box>
    </Paper>
  );
};

export default RightBar;
