import React from 'react';
import propTypes from 'prop-types';

import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { spacing } from '@material-ui/system';
import { Typography as MuiTypography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { fontFamily, sizeFont } from './typographys';

const TypographySpacing = styled(MuiTypography)(spacing);

export default function Typography({ children, style, font, size, loading, ...props }) {
  const fontSizeStyle = sizeFont[size];
  const fontNameStyle = fontFamily[font];
  return (
    <>
      {loading ? (
        <Skeleton animation="wave" width={100} />
      ) : (
        <>
          <TypographySpacing style={{ ...fontSizeStyle, ...fontNameStyle, ...style }} {...props}>
            {children}
          </TypographySpacing>
        </>
      )}
    </>
  );
}

Typography.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: propTypes.object,
  // eslint-disable-next-line react/require-default-props
  font: propTypes.string,
  loading: propTypes.bool,
  size: propTypes.string,
  children: propTypes.node.isRequired,
};

Typography.defaultProps = {
  style: {},
  loading: false,
  font: 'medium',
  size: 'medium',
};
