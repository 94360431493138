export default [
  {
    name: 'Lime Green',
    value: '#799B67',
  },
  {
    name: 'May Green',
    value: '#53783E',
  },
  {
    name: 'Green Oxide',
    value: '#326935',
  },
  {
    name: 'Turquoise Mint',
    value: '#3E7673',
  },
  {
    name: 'Pastel Blue',
    value: '#498397',
  },
  {
    name: 'Pigeon Blue',
    value: '#5C7985',
  },
  {
    name: 'Steel Blue',
    value: '#415967',
  },
  {
    name: 'Sky Blue',
    value: '#2D7D97',
  },
  {
    name: 'Sand Beige',
    value: '#AD9460',
  },

  {
    name: 'Yellow Ochre',
    value: '#9E6D2E',
  },
  {
    name: 'Autumn Brown',
    value: '#774727',
  },
  {
    name: 'Red Oxide',
    value: '#632721',
  },
  {
    name: 'Capri Blue',
    value: '#0A334F',
  },
  {
    name: 'Stone Grey',
    value: '#9C948A',
  },
  {
    name: 'Dusty Grey',
    value: '#6B736E',
  },
  {
    name: 'Iron Grey',
    value: '#393F41',
  },
];
