/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import Typography from 'core/ui/Typography';
import { Collapse, makeStyles } from '@material-ui/core';
import MIcon from 'core/ui/MIcon';
import Box from 'core/ui/Box';
import { Colors } from 'core/constants';
import useMenuCollapse from 'core/utils/useMenuCollapse';

const useStyles = makeStyles(() => ({
  tab: {
    borderBottom: `1px solid ${Colors.secondary.xLight}`,
    position: 'relative',
  },

  buttonAction: {
    position: 'absolute',
    top: 8,
    right: 50,
  },
}));

const TabCourt = ({ tab }) => {
  const [openNew, setOpenNew] = useState(false);
  const { isOpen, collapseMenu } = useMenuCollapse();
  const tabId = `${tab.id}-OPTIONS`;
  const open = isOpen(tabId);
  const classes = useStyles();
  return (
    <Box className={classes.tab}>
      <Box className="hover" onClick={() => collapseMenu(tabId, !open)}>
        <Box p={3} display="flex" alignItems="center">
          <Box flexGrow={1} alignItems="center">
            <Typography font="bold" size="medium">
              {tab.label} Courts
            </Typography>
          </Box>

          <Box className="hover">
            <MIcon icon={open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
          </Box>
        </Box>
      </Box>
      <Collapse in={open && openNew}>
        <Box p={3}>
          {tab.form({
            callback: () => {
              setOpenNew(false);
            },
          })}
        </Box>
      </Collapse>
      <Collapse in={open}>
        <Box p={3}>{tab.list({ tab })}</Box>
      </Collapse>
    </Box>
  );
};

export default TabCourt;
