import { makeStyles } from '@material-ui/core';
import { Colors } from 'core/constants';

const useStylesPanel = makeStyles(() => ({
  loaderContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 10,
  },
  loaderContent: {
    backgroundColor: Colors.secondary.white,
    width: 300,
    height: 150,
    borderRadius: 20,
  },
}));

export default useStylesPanel;
