import React, { useContext } from 'react';
import { DrawContext } from 'core/contexts';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Divider from 'core/ui/Divider';
import FontIcon from 'core/ui/FontIcon';
import Scrollbars from 'react-custom-scrollbars';
import useStylesRightBar from './style';

const Elements = () => {
  const classes = useStylesRightBar();
  const drawContext = useContext(DrawContext);
  if (!drawContext.activeMenu) {
    return <></>;
  }

  return (
    <Paper elevation={1} className={classes.root}>
      <Box px={3} py={2} display="flex" alignItems="center" justifyContent="center">
        {drawContext.activeMenu.icon && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <FontIcon iconName={drawContext.activeMenu.icon} size={20} />
          </Box>
        )}
        <Box flexGrow={1} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography ml={3} font="bold" size="medium" noWrap>
            {drawContext.activeMenu.title}
          </Typography>
        </Box>
        <Box className="hover" onClick={() => drawContext.setActiveMenu(null)}>
          <FontIcon iconName="icon-arrow-expand-right" size={20} />
        </Box>
      </Box>
      <Divider variant="fullWidth" mb={2} />
      <Box style={{ height: '100%' }} pb={15}>
        <Scrollbars
          renderTrackHorizontal={(props) => (
            <div {...props} style={{ display: 'none' }} className="track-horizontal" />
          )}
        >
          <Box>{drawContext.activeMenu.form}</Box>
        </Scrollbars>
      </Box>
    </Paper>
  );
};

export default Elements;
