import { makeStyles } from '@material-ui/core';

const useStylesCenter = makeStyles(() => ({
  room: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStylesCenter;
