/* eslint-disable no-param-reassign */
import { fabric } from 'fabric';
import usePosition from 'components/Draw/position';
import { v4 as uuidv4 } from 'uuid';

const Z_INDEX_COURT = 20;
const Z_INDEX_OUTLINE = 100;
const outlinePickleBall =
  'M438.36,1.79v196.8H1.79V1.79H438.36M440,.12H.12V200.26H440V.12ZM220.91,1.79h-1.67v196.8h1.67Zm-70,0h-1.66v196.8h1.66Zm140,0h-1.66v196.8h1.66ZM149.26,99.36H1.79V101H149.26Zm289.1,0H290.89V101H438.36Z';
const useDrawPickleCourt = ({ scaleRatio }) => {
  const { rotate, centerCanvas, positionElement } = usePosition();

  const newLine = ({ x1, y1, x2, y2, strokeWidth, strokeColor = '#000' }) => {
    const line = new fabric.Line([x1, y1, x2, y2], {
      stroke: strokeColor,
      strokeWidth: Number(strokeWidth),
    });
    line.selectable = false;

    return line;
  };

  const drawBordersCourt = ({ court, rectCourt }) => {
    const lineBorderTop = newLine({
      x1: 0,
      y1: 0,
      x2: rectCourt.width,
      y2: 0,
      strokeWidth: court.sideLineWidth,
      strokeColor: court.marginColor,
    });
    lineBorderTop.top = rectCourt.top - court.sideLineWidth;
    lineBorderTop.left = rectCourt.left;

    const lineBorderBottom = fabric.util.object.clone(lineBorderTop);
    lineBorderBottom.top = rectCourt.top + rectCourt.height;

    const lineBorderLeft = newLine({
      x1: 0,
      y1: 0,
      x2: 0,
      y2: rectCourt.height + Number(court.sideLineWidth) * 2,
      strokeWidth: court.baseLineWidth,
      strokeColor: court.marginColor,
    });
    lineBorderLeft.top = rectCourt.top - Number(court.sideLineWidth);
    lineBorderLeft.left = rectCourt.left - Number(court.baseLineWidth) + 0.5;

    const lineBorderRight = fabric.util.object.clone(lineBorderLeft);
    lineBorderRight.left = lineBorderRight.left + rectCourt.width + Number(court.baseLineWidth) - 1;
    return new fabric.Group([lineBorderTop, lineBorderBottom, lineBorderLeft, lineBorderRight], {});
  };

  const drawOutlineCourt = ({ canvas, court, groupCourt }) => {
    const outlinePath = new fabric.Path(outlinePickleBall, {});
    outlinePath.id = uuidv4();
    groupCourt.outlineElementId = outlinePath.id;
    outlinePath.left = groupCourt.left;
    outlinePath.top = groupCourt.top;
    outlinePath.fill = 'transparent';
    outlinePath.stroke = court.outlineColor;
    outlinePath.strokeWidth = 0.2;
    outlinePath.selectable = false;
    outlinePath.scaleToWidth(court.length * scaleRatio);

    if (court.layoutCross) {
      rotate(90, outlinePath);
    }
    outlinePath.zIndex = Z_INDEX_OUTLINE;
    canvas.add(outlinePath);
  };

  const drawPickleCourt = ({ canvas, room, court, position }) => {
    const voleyCourt = new fabric.Path(outlinePickleBall, {
      strokeColor: 'transparent',
      fill: court.boundaryColor,
    });
    voleyCourt.scaleToWidth(court.length * scaleRatio);

    // voleyCourt.width = court.length * voleyCourt.scaleY;
    // voleyCourt.height = court.width * voleyCourt.scaleX;
    const arrayGroupCourt = [voleyCourt];
    if (court.sideLineWidth > 0 || court.baseLineWidth > 0) {
      const groupBorder = drawBordersCourt({ court, voleyCourt });
      groupBorder.zIndex = court.breakBorder ? 5 : 2;
      if (court.main) {
        groupBorder.selectable = false;
        canvas.add(groupBorder);
      } else {
        arrayGroupCourt.push(groupBorder);
      }
    }

    const groupCourt = new fabric.Group(arrayGroupCourt, {});
    groupCourt.id = court.id;
    groupCourt.lockRotation = true;
    groupCourt.lockScalingX = true;
    groupCourt.lockScalingY = true;
    if (court.layoutCross) {
      groupCourt.cross = true;
      rotate(90, groupCourt);
    }

    centerCanvas(canvas, groupCourt);
    if (position) {
      positionElement({ element: groupCourt, room, position });
    }

    if (court.main) {
      groupCourt.selectable = false;
      centerCanvas(canvas, groupCourt);
    }

    if (court.showOutline) {
      drawOutlineCourt({ canvas, court, groupCourt });
    }
    groupCourt.zIndex = Z_INDEX_COURT;
    canvas.add(groupCourt);
    canvas.renderAll();
  };

  return {
    drawPickleCourt,
  };
};

export default useDrawPickleCourt;
