import React, { useState } from 'react';
import propTypes from 'prop-types';
import './style.css';
import { useSelector } from 'react-redux';
import { appSelectors } from 'modules/app';
import { Colors } from 'core/constants';
import { Collapse } from '@material-ui/core';
import Typography from '../Typography';
import Box from '../Box';
import SelectBoxObject from '../SelectBoxObject';
import TextField from '../TextField';
import { ButtonSecondary } from '../Button';
import MIcon from '../MIcon';
import Grid from '../Grid';
import Radio from '../Radio';

const fontOptions = [
  'Squada One',
  'Dancing Script',
  'Roboto',
  'Racing Sans One',
  'Bree Serif',
  'Alfa Slab One',
];
export default function FontPicker({ setFont, font, label }) {
  const fonts = useSelector((state) => appSelectors.getGoogleFonts(state));
  const [showFonts, setShowFonts] = useState(false);

  return (
    <>
      <Box flex={1}>
        <Typography font="medium" size="label" color="secondary">
          {label}
        </Typography>
        <Box display="flex">
          <Box>
            <TextField
              value={font !== 'custom' ? font : ''}
              disabled
              onClick={() => setShowFonts(true)}
            />
          </Box>
          <Box flexGrow={1} ml={2}>
            <ButtonSecondary onClick={() => setShowFonts(!showFonts)}>
              Font Options
              <MIcon
                icon={showFonts ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                color={Colors.secondary.white}
              />
            </ButtonSecondary>
          </Box>
        </Box>
      </Box>
      <Box>
        <Collapse in={showFonts}>
          <Grid mt={3} mb={1} container spacing={3}>
            {fontOptions.map((c, index) => (
              <Grid item xs={6} key={index.toString()}>
                <Box
                  display="flex"
                  alignItems="center"
                  className="hover"
                  onClick={() => setFont(c)}
                >
                  <Radio size="small" checked={font === c} />
                  <Typography ml={2} font="medium" size="xxSmall" color="secondary">
                    {c}
                  </Typography>
                </Box>
              </Grid>
            ))}
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                className="hover"
                onClick={() => setFont('custom')}
              >
                <Radio
                  size="small"
                  checked={font === 'custom' || !fontOptions.find((x) => x === font)}
                />
                <Typography ml={2} font="medium" size="xxSmall" color="secondary">
                  Custom
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      </Box>

      {(font === 'custom' || !fontOptions.find((x) => x === font)) && showFonts && (
        <Box>
          <SelectBoxObject
            value={font}
            setValue={(v) => setFont(v)}
            placeholder="Select a custom font"
            label="Select a custom font"
            options={fonts}
            propLabel="family"
            propValue="family"
          />
        </Box>
      )}
    </>
  );
}

FontPicker.propTypes = {
  font: propTypes.string,
  setFont: propTypes.func.isRequired,
  label: propTypes.string,
};

FontPicker.defaultProps = {
  label: '',
  font: '',
};
