/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { Collapse, makeStyles } from '@material-ui/core';
import { DrawContext } from 'core/contexts';
import MIcon from 'core/ui/MIcon';
import { ButtonSelect, ButtonRemoveCourt } from 'core/ui/Button';
import Divider from 'core/ui/Divider';
import Paper from 'core/ui/Paper';
import useMenuCollapse from 'core/utils/useMenuCollapse';
import { OpenWith, RemoveCircle } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  tab: {
    // border: `0.8px solid ${Colors.secondary.xLight}`,
    position: 'relative',
  },
  buttonRemove: {
    position: 'absolute',
    top: 12,
    right: 50,
  },
  buttonSelect: {
    position: 'absolute',
    top: 12,
    right: 90,
  },
}));

const ItemList = ({ tab, court, index, removeCourt, selectCourt }) => {
  const classes = useStyles();
  const { isOpen, collapseMenu } = useMenuCollapse();

  const open = isOpen(court.id);
  return (
    <Paper className={classes.tab} mb={1}>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        className="hover"
        onClick={() => collapseMenu(court.id, !open)}
      >
        <Box flexGrow={1}>
          <Typography font="bold" size="small">
            {court.name || `COURT - ${index + 1}`}
          </Typography>
        </Box>
        <Box onClick={() => collapseMenu(court.id, !open)}>
          <MIcon icon={open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
        </Box>
      </Box>
      {!court.main && (
        <Box className={classes.buttonSelect}>
          <ButtonSelect onClick={() => selectCourt(court)}>
            <OpenWith fontSize="small" />
          </ButtonSelect>
        </Box>
      )}
      <Box className={classes.buttonRemove}>
        <ButtonRemoveCourt onClick={() => removeCourt(court)}>
          <RemoveCircle fontSize="small" />
        </ButtonRemoveCourt>
      </Box>
      <Box>
        {open && <Divider mx={3} />}
        <Collapse in={open}>
          <Box p={3}>{tab.form({ current: court })}</Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

const BasketCourtList = ({ tab }) => {
  const { courts, removeElement, selectElement } = useContext(DrawContext);

  const basketCourts = courts.filter((x) => x.layoutType === tab.value);
  return (
    <Box mb={5}>
      {basketCourts &&
        basketCourts.map((court, index) => (
          <ItemList
            key={index.toString()}
            tab={tab}
            court={court}
            removeCourt={removeElement}
            selectCourt={selectElement}
            index={index}
          />
        ))}
    </Box>
  );
};

export default BasketCourtList;
