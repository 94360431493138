import { makeStyles } from '@material-ui/core';
import { Colors, NAV_BAR_HEIGHT } from 'core/constants';

const useStylesLeftBar = makeStyles(() => ({
  root: {
    backgroundColor: Colors.secondary.white,
    position: 'fixed',
    width: 170,
    top: NAV_BAR_HEIGHT,
    maxHeight: 400,
    minHeight: 200,
    borderRadius: 0,
    zIndex: 10,
  },
  buttonRightBar: {
    backgroundColor: Colors.secondary.white,
    height: 50,
    width: '100%',
    borderRadius: 0,
  },
  activeButton: {
    backgroundColor: Colors.secondary.light,
  },
}));

export default useStylesLeftBar;
