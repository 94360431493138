import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import imgLoadingIntro from 'assets/images/loader-2.gif';
import Box from '../Box';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 2000,
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  img: {
    maxWidth: 600,
  },
}));

export default function Splash() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography align="right" size="large" font="bold" color="inherit">
        GymFloorDesigns.com
      </Typography>
      <Box>
        <img src={imgLoadingIntro} alt="live" style={{ width: 100 }} />
      </Box>
    </div>
  );
}
