/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

import LinearProgress from 'core/ui/LinearProgress';
import { BUCKET_ACCESS_ID, BUCKET_SECRET_ACESS_KEY, ENV } from 'core/env';
import Box from '../Box';

const bucket = new AWS.S3({
  accessKeyId: BUCKET_ACCESS_ID,
  secretAccessKey: BUCKET_SECRET_ACESS_KEY,
  region: 'us-east-2',
});

const PATH_KEY = `uploads/${ENV}/images/`;

export default function UploadS3({ setComplete, fileUpload }) {
  const [progressUpload, setProgressUpload] = useState(0);

  const uploadfile = (fileName, file) => {
    const params = {
      Bucket: 'foster-uploads',
      Key: PATH_KEY + fileName,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params, (err, data) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      setComplete({ complete: true, ...data });
      return true;
    });
  };

  const uploadSampleFile = () => {
    const uniqueFileName = `foster-image-${uuidv4()}-${fileUpload.name}`;

    uploadfile(uniqueFileName, fileUpload.file).on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
      setProgressUpload(progressPercentage);
    });
  };

  useEffect(() => {
    uploadSampleFile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {fileUpload && (
        <Typography align="center" ml={3} color="primary" variant="h4">
          {fileUpload.name.substring(0, 30)}
        </Typography>
      )}
      <LinearProgress
        style={{ height: 15, width: '100%', borderRadius: 20 }}
        value={progressUpload}
      />
    </Box>
  );
}
