import Layout from 'pages/shared/Layout/index';
import Main from '../Main';
import Home from '../pages/Home';
import PanelDraw from '../pages/Panel';

const Routes = () => [
  {
    component: Main,
    routes: [
      {
        component: Layout,
        routes: [
          {
            path: '/',
            exact: true,
            component: Home,
            public: true,
          },
          {
            path: '/Draw',
            exact: true,
            component: PanelDraw,
            public: true,
          },
          {
            path: '/open/:uuid',
            exact: true,
            component: PanelDraw,
            public: true,
          },
        ],
      },
    ],
  },
];

export default Routes;
