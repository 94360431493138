import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import * as selectors from './selectors';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    collapseMenu: ['id', 'state'],
  },
  { prefix: 'MENU/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  collapseMenuState: {},
});

/* ---------- Reducers ---------- */
const addReducer = (state, action) => {
  const newState = { ...state.collapseMenuState };
  newState[action.id] = action.state;
  return { ...state, collapseMenuState: newState };
};

/* ---------- Main Reducer ---------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.COLLAPSE_MENU]: addReducer,
});

export const menuActions = Creators;
export const menuTypes = Types;
export const menuSelectors = selectors;

export default { menuActions, menuSelectors };
