import { makeStyles } from '@material-ui/core';
import { Colors } from 'core/constants';

const useStylesColorPicker = makeStyles(() => ({
  boxPreviewColor: {
    width: 35,
    height: 33,
    borderRadius: 5,
    border: `1px solid ${Colors.secondary.solid}`,
    display: 'block',
  },
  boxPreviewOptionsColor: {
    width: 18,
    height: 18,
    borderRadius: 5,
    border: `0.5px solid ${Colors.secondary.solid}`,
    display: 'block',
  },
  inputColor: {
    width: 90,
  },
}));

export default useStylesColorPicker;
