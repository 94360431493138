import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import useWindowSize from 'core/utils/useWindowSize';
import { DrawContext } from 'core/contexts';

import Loader from 'core/ui/Loader';
import { projectsSelectors } from 'modules/projects';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import Canvas from './Canvas';
import useStylesPanel from './style';
import RightBar from './Tools/RightBar';
import LeftBar from './Tools/LeftBar';
import ModalSucess from './ModalSucess';
import ModalDuplicate from './ModalDuplicate';
import ModalStart from './ModalStart';
import ModalClearAll from './ModalClearAll';
import TopBar from './Tools/TopBar';
import ModalPreview from './ModalPreview';

const Panel = () => {
  const loading = useSelector((state) => projectsSelectors.getLoadingSubmit(state));
  const size = useWindowSize();
  const classes = useStylesPanel({ size });
  const drawContext = useContext(DrawContext);

  return (
    <>
      <Box display="flex" alignItems="center" flexGrow={1} flex={1} style={{ width: '100%' }}>
        <TopBar />
        {(loading || drawContext.loading) && <Loader />}

        {!drawContext.viewOnly && <RightBar />}
        <Box className={classes.panel} style={{ width: '100%', height: '100%' }}>
          <Scrollbars
            autoHide={false}
            autoHeight
            autoHeightMin={size.height}
            onScroll={() => console.log('scrolling')}
            ref={(scrollbar) => drawContext.setScrollRef(scrollbar)}
          >
            <Canvas />
          </Scrollbars>
        </Box>
        {!drawContext.viewOnly && <LeftBar />}
      </Box>
      {drawContext.modalDuplicate && (
        <ModalDuplicate project={drawContext.project} duplicate={drawContext.duplicate} />
      )}
      {drawContext.modalEditing && (
        <ModalClearAll
          close={() => drawContext.setModalEditing(false)}
          clearData={() => {
            drawContext.setModalEditing(false);
            drawContext.clearData();
          }}
        />
      )}
      {drawContext.modalStart && <ModalStart close={() => drawContext.setModalStart(false)} />}
      {drawContext.showSuccess && (
        <ModalSucess project={drawContext.project} clearData={drawContext.clearData} />
      )}
      {drawContext.modalPreview.open && (
        <ModalPreview
          imageBase64={drawContext.modalPreview.imageBase64}
          close={() => drawContext.setModalPreview(() => ({ open: false }))}
        />
      )}
    </>
  );
};

export default Panel;
