/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Dialog, makeStyles } from '@material-ui/core';
import { Colors } from 'core/constants';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'baseline', // default center
  },
  box: {
    borderRadius: 10,
    backgroundColor: Colors.secondary.solid,
  },
});

const ModalClearAll = ({ close, clearData }) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        classes={{ scrollPaper: classes.scrollPaper, paper: classes.box }}
        fullWidth
        maxWidth="sm"
        onClose={() => close()}
        open
      >
        <Box p={5} style={{ borderRadius: 10 }}>
          {/* <Typography
            align="center"
            size="xLarge"
            font="bold"
            style={{ color: Colors.secondary.white }}
          >
            Welcome
          </Typography>
          <Typography size="xLarge" font="medium" style={{ color: Colors.secondary.white }}>
            Start out by selecting the Room Size area to begin your floor configuration.
          </Typography> */}
          <Typography
            align="center"
            size="xLarge"
            font="bold"
            style={{ color: Colors.secondary.white }}
          >
            Do you want continue editing this project?
          </Typography>
          <Box display="flex" flexGrow={1} p={5} mt={3}>
            <ButtonPrimary style={{ height: 60, marginRight: 3 }} fullWidth onClick={() => close()}>
              <Typography color="secondary" size="medium" font="medium">
                Continue
              </Typography>
            </ButtonPrimary>
            <ButtonPrimary
              style={{ height: 60, marginLeft: 3 }}
              fullWidth
              onClick={() => clearData(true)}
            >
              <Typography color="secondary" size="medium" font="medium">
                Clear All
              </Typography>
            </ButtonPrimary>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalClearAll;
