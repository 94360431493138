/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Dialog, makeStyles } from '@material-ui/core';
import { Colors } from 'core/constants';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import { APP_BASE_URL } from 'core/env';

const useStyles = makeStyles({
  box: {
    borderRadius: 10,
    backgroundColor: Colors.secondary.solid,
  },
});
const ModalSucess = ({ clearData, project }) => {
  const classes = useStyles();
  const projectUrl = `${APP_BASE_URL}/open/${project.uuid}`;
  const history = useHistory();

  return (
    <>
      <Dialog fullWidth classes={{ paper: classes.box }} maxWidth="sm" open>
        <Box p={5} style={{ backgroundColor: Colors.secondary.solid }}>
          <Typography
            align="center"
            size="xLarge"
            font="bold"
            style={{ color: Colors.secondary.white }}
          >
            Thank You
          </Typography>
          <Typography size="xLarge" font="medium" style={{ color: Colors.secondary.white }}>
            You should receive a copy of your design shortly.
          </Typography>
          <Typography
            align="center"
            size="medium"
            font="medium"
            mt={3}
            style={{ color: Colors.secondary.white }}
          >
            Project Url
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              align="center"
              size="medium"
              color="primary"
              font="medium"
              my={3}
              mr={1}
              style={{ color: Colors.secondary.white }}
            >
              {projectUrl}
            </Typography>
          </Box>

          <Box p={5} mt={3} px={50}>
            <ButtonPrimary
              style={{ height: 60 }}
              fullWidth
              onClick={() => {
                clearData();
                history.push('/');
              }}
            >
              <Typography color="secondary" size="xLarge" font="medium">
                OK
              </Typography>
            </ButtonPrimary>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalSucess;
