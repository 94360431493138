import { persistData, removeData, retrieveData } from 'core/utils/session-storage';

export default () => {
  const saveLocal = (room, courts, texts, images, positions, scalings, rotations) => {
    removeData('LOCAL_PROJECT');
    const dataString = JSON.stringify({
      room: { ...room, element: null, elementFill: null },
      courts,
      texts,
      images,
      positions,
      scalings,
      rotations,
    });

    persistData('LOCAL_PROJECT', dataString);
  };

  const getLocalData = () => {
    const data = retrieveData('LOCAL_PROJECT');

    if (data) {
      return JSON.parse(data);
    }

    return null;
  };

  const resetStorage = () => {
    removeData('LOCAL_PROJECT');
  };

  return {
    saveLocal,
    getLocalData,
    resetStorage,
  };
};
