import React from 'react';

import { Box } from '@material-ui/core';
import useStylesCenter from './style';

const Canvas = () => {
  const classes = useStylesCenter();
  return (
    <Box className={classes.root}>
      <Box>
        <canvas id="canvas" className={classes.room} />
      </Box>
    </Box>
  );
};

export default Canvas;
