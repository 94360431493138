export default [
  {
    name: 'White',
    value: '#FFFFFF',
  },
  {
    name: 'Light Yellow',
    value: '#FFE400',
  },
  {
    name: 'Yellow',
    value: '#F5B300',
  },
  {
    name: 'Orange',
    value: '#E04800',
  },
  {
    name: 'Red',
    value: '#C0142E',
  },
  {
    name: 'Maroon',
    value: '#7F2826',
  },
  {
    name: 'Black',
    value: '#454444',
  },
  {
    name: 'Brown',
    value: '#513726',
  },
  {
    name: 'Gold',
    value: '#877148',
  },

  {
    name: 'Light Beige',
    value: '#E1C5AA',
  },
  {
    name: 'Dark Blue',
    value: '#0D2242',
  },
  {
    name: 'Light blue',
    value: '#003A74',
  },
  {
    name: 'N.C. Blue',
    value: '#63B3E8',
  },
  {
    name: 'Purple',
    value: '#300A74',
  },
  {
    name: 'Green',
    value: '#16553D',
  },
  {
    name: 'Light Green',
    value: '#147928',
  },
  {
    name: 'Grey',
    value: '#8B8D8D',
  },
];
