import React from 'react';
import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import woodColorsOptions from './woodColorsOptions';

import Typography from '../Typography';
import useStylesColorPicker from './style';
import Grid from '../Grid';
import Radio from '../Radio';

export default function WoodColors({ setColor, color, label, hideNone }) {
  const classes = useStylesColorPicker();

  // eslint-disable-next-line react/prop-types
  const Option = ({ value, vColor, name }) => (
    <Box display="flex" alignItems="center" className="hover" onClick={() => setColor(value)}>
      <Radio size="small" checked={color === value} />
      {value && (
        <Box mx={1} className={classes.boxPreviewOptionsColor} style={{ backgroundColor: vColor }}>
          &nbsp;
        </Box>
      )}
      <Typography ml={1} font="medium" size="xxSmall" color="secondary">
        {name}
      </Typography>
    </Box>
  );

  return (
    <>
      {label && (
        <Box flex={1}>
          <Typography font="medium" size="label" color="secondary">
            {label}
          </Typography>
        </Box>
      )}
      <Box>
        <Grid mb={3} container spacing={3}>
          {woodColorsOptions
            .filter((x) => !hideNone || !!x.value)
            .map((c, index) => (
              <Grid item xs={6} key={index.toString()}>
                <Option value={c.value} vColor={c.color} name={c.name} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
}

WoodColors.propTypes = {
  color: propTypes.string,
  setColor: propTypes.func.isRequired,
  label: propTypes.string,
  hideNone: propTypes.bool,
};

WoodColors.defaultProps = {
  label: '',
  color: '',
  hideNone: false,
};
