import React, { useContext } from 'react';

import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';

import { DrawContext } from 'core/contexts';
import Form from './Form';

const Finish = () => {
  const { project, saveProject } = useContext(DrawContext);

  const mb = 2;
  return (
    <Box mb={10}>
      <Typography mx={3} mb={mb} font="regular" size="xSmall">
        Complete the form below to submit your design to our team And to also received a copy of
        your design.
      </Typography>
      <Typography mx={3} font="medium" size="medium">
        Estimate Submission
      </Typography>
      <Box p={5}>
        <Form current={project} saveProject={(p) => saveProject(p)} />
      </Box>
    </Box>
  );
};

export default Finish;
