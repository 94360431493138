/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Dialog, makeStyles } from '@material-ui/core';
import { Colors } from 'core/constants';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';

const useStyles = makeStyles({
  box: {
    borderRadius: 10,
    backgroundColor: Colors.secondary.solid,
  },
});

const ModalStart = ({ close }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog onClose={() => close()} classes={{ paper: classes.box }} fullWidth maxWidth="sm" open>
        <Box p={5} style={{ backgroundColor: Colors.secondary.solid }}>
          <Typography
            align="center"
            size="xLarge"
            font="bold"
            style={{ color: Colors.secondary.white }}
          >
            Welcome
          </Typography>
          <Typography size="xLarge" font="medium" style={{ color: Colors.secondary.white }}>
            Start out by selecting the Room Size area to begin your floor configuration.
          </Typography>
          <Box p={5} mt={3} px={50}>
            <ButtonPrimary style={{ height: 60 }} fullWidth onClick={() => close()}>
              <Typography color="secondary" size="medium" font="medium">
                Got it
              </Typography>
            </ButtonPrimary>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalStart;
