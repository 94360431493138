import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// import session from 'modules/session';

import { reducer as notifications } from 'modules/notifications';
import { reducer as menu } from 'modules/menu';
import { reducer as app } from 'modules/app';
import { reducer as projects } from 'modules/projects';

const rootReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    menu,
    app,
    projects,
  });

export default rootReducers;
