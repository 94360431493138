import React, { useState } from 'react';

import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { ButtonAddCourt } from 'core/ui/Button';
import { Colors } from 'core/constants';
import { Collapse, makeStyles } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import List from './List';
import Form from './Form';

const useStyles = makeStyles(() => ({
  tab: {
    borderBottom: `1px solid ${Colors.secondary.xLight}`,
    position: 'relative',
  },

  buttonAction: {
    position: 'absolute',
    top: 8,
    right: 10,
  },
}));

const DrawText = () => {
  const [openNew, setOpenNew] = useState(false);

  const classes = useStyles();
  const mb = 2;
  return (
    <Box mb={10}>
      <Typography mx={3} mb={mb} font="regular" size="xSmall">
        Choose a font and add text to any area of your floor design.
      </Typography>

      <Box className={classes.tab}>
        <Box className="hover">
          <Box p={3} display="flex" alignItems="center">
            <Box flexGrow={1} alignItems="center">
              <Typography font="bold" size="medium">
                Court Text
              </Typography>
            </Box>

            {/* <Box className="hover">
              <MIcon icon={open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
            </Box> */}
          </Box>
        </Box>

        <Collapse in={openNew}>
          <Paper mt={1} mx={5} p={5}>
            <Form current={{}} callback={() => setOpenNew(false)} />
          </Paper>
        </Collapse>

        <Box p={3}>
          <List />
        </Box>

        <Box className={classes.buttonAction}>
          <ButtonAddCourt
            onClick={() => {
              setOpenNew(!openNew);
            }}
          >
            <Typography font="medium" size="small">
              Add Text
            </Typography>
          </ButtonAddCourt>
        </Box>
      </Box>
    </Box>
  );
};

export default DrawText;
