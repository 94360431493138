import { projectsSagas, projectsTypes } from 'modules/projects';
import { all, takeLatest } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    // ********** PROJECTS **********
    takeLatest(projectsTypes.POST_PROJECT_START, projectsSagas.postProject),
    takeLatest(projectsTypes.GET_PROJECTS_START, projectsSagas.getProjects),
    takeLatest(projectsTypes.GET_PROJECT_START, projectsSagas.getProject),
  ]);
}
