/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { Collapse, makeStyles } from '@material-ui/core';
import { DrawContext } from 'core/contexts';
import MIcon from 'core/ui/MIcon';
import Divider from 'core/ui/Divider';
import Paper from 'core/ui/Paper';
import useMenuCollapse from 'core/utils/useMenuCollapse';

const useStyles = makeStyles(() => ({
  tab: {
    position: 'relative',
  },
  buttonAction: {
    position: 'absolute',
    top: 8,
    right: 50,
  },
}));

const ItemList = ({ tab, court, index }) => {
  const classes = useStyles();
  const { isOpen, collapseMenu } = useMenuCollapse();
  const tabId = `${court.id}-OPTIONS`;
  const open = isOpen(tabId);

  return (
    <Paper mb={1} className={classes.tab}>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        className="hover"
        onClick={() => collapseMenu(tabId, !open)}
      >
        <Box flexGrow={1}>
          <Typography font="bold" size="small">
            {court.name || `COURT - ${index + 1}`}
          </Typography>
        </Box>
        <Box onClick={() => collapseMenu(tabId, !open)}>
          <MIcon icon={open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
        </Box>
      </Box>
      <Box>
        {open && <Divider mx={3} />}
        <Collapse in={open}>
          <Box p={3}>{tab.form({ current: court })}</Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

const BasketCourtList = ({ tab }) => {
  const { courts, removeElement } = useContext(DrawContext);

  const basketCourts = courts.filter((x) => x.layoutType === tab.value && x.main);
  return (
    <Box mb={5}>
      {basketCourts &&
        basketCourts.map((court, index) => (
          <ItemList
            key={index.toString()}
            tab={tab}
            court={court}
            removeCourt={removeElement}
            index={index}
          />
        ))}
    </Box>
  );
};

export default BasketCourtList;
