/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Dialog, makeStyles } from '@material-ui/core';
import { Colors } from 'core/constants';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';

const useStyles = makeStyles({
  box: {
    borderRadius: 10,
    backgroundColor: Colors.secondary.solid,
  },
});

const ModalTerms = ({ close }) => {
  const classes = useStyles();
  const styleLi = { marginBottom: 3 };
  return (
    <>
      <Dialog onClose={() => close()} classes={{ paper: classes.box }} fullWidth maxWidth="sm" open>
        <Box p={5} style={{ backgroundColor: Colors.secondary.solid }}>
          <Typography
            align="center"
            size="xLarge"
            font="bold"
            style={{ color: Colors.secondary.white }}
          >
            TERMS AND CONDITIONS
          </Typography>
          <ul>
            <li style={styleLi}>
              <Typography size="medium" font="medium" style={{ color: Colors.secondary.white }}>
                This website/tool is for general design purposes only, it is not to be used for
                construction or official size/dimensions in the field. We've done our best to make
                this tool as accurate as possible, but any discrepancies between the designs here
                and real life situations are not the responsibility of GymFloorDesigns.com.
              </Typography>
            </li>
            <li style={styleLi}>
              <Typography size="medium" font="medium" style={{ color: Colors.secondary.white }}>
                By submitting your contact information to receive a copy of the design, you agree to
                potentially be contacted by one of our partner sports flooring contractors about
                your project. You are in no way obligated to work with them, but they may reach out
                to follow up for more information about your project.
              </Typography>
            </li>
            <li style={styleLi}>
              <Typography size="medium" font="medium" style={{ color: Colors.secondary.white }}>
                We do not have a technical support team in place for this tool/website. If you have
                a concern or question about the site, please fill out the contact form with your
                question and we will get back to you soon.
              </Typography>
            </li>
          </ul>

          <Box p={5} mt={3} px={50}>
            <ButtonPrimary style={{ height: 30 }} fullWidth onClick={() => close()}>
              <Typography color="secondary" size="medium" font="medium">
                Close
              </Typography>
            </ButtonPrimary>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalTerms;
