/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import TextField from 'core/ui/TextField';
import Box from 'core/ui/Box';
import { ButtonCancel, ButtonSecondary } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import notifications from 'modules/notifications';
import { projectsSelectors } from 'modules/projects';
import SelectBox from 'core/ui/SelectBox';
import { statesFloor } from 'core/constants';
import Checkbox from 'core/ui/Checkbox';
import Typography from 'core/ui/Typography';
import { Link } from '@material-ui/core';
import ModalTerms from './ModalTerms';

const FormFinish = ({ current, saveProject, cancel }) => {
  const loading = useSelector((state) => projectsSelectors.getLoading(state));
  const [showSave, setShowSave] = useState(false);
  const [modalTerms, openModalTerms] = useState(false);
  const [currentElement, setCurrentElement] = useState({
    color: '#000',
    font: 'Roboto',
    fontSize: 30,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (current) {
      setCurrentElement((prev) => ({
        ...prev,
        ...current,
      }));
    }
  }, [current]);

  useEffect(() => {
    setShowSave(true);
  }, [currentElement]);

  const validate = () => {
    if (!currentElement.name) {
      dispatch(notifications.warning('Name is required.'));
      return false;
    }
    if (!currentElement.email) {
      dispatch(notifications.warning('Email is required.'));
      return false;
    }
    if (!currentElement.city) {
      dispatch(notifications.warning('City & State is required.'));
      return false;
    }
    return true;
  };

  const save = () => {
    if (!validate()) {
      return;
    }
    setShowSave(false);
    saveProject(currentElement);
  };

  const mb = 2;
  return (
    <Box mb={3}>
      {modalTerms && <ModalTerms close={() => openModalTerms(false)} />}
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, name: v }))}
          value={currentElement.name || ''}
          label="Name"
          required
        />
      </Box>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, email: v }))}
          value={currentElement.email || ''}
          label="Email Address"
          required
        />
      </Box>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, phone: v }))}
          value={currentElement.phone || ''}
          label="Phone"
        />
      </Box>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, company: v }))}
          value={currentElement.company || ''}
          label="Name of your School/Business"
        />
      </Box>
      <Box mb={mb}>
        <SelectBox
          label="State"
          options={statesFloor}
          setValue={(v) => setCurrentElement((prev) => ({ ...prev, state: v }))}
          value={currentElement.state || ''}
        />
      </Box>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, city: v }))}
          value={currentElement.city || ''}
          label="City"
          required
        />
      </Box>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, message: v }))}
          value={currentElement.message || ''}
          label="Additional Message or Questions"
          rows={10}
          multiline
          required
        />
      </Box>

      <Box display="flex">
        <Checkbox
          checked={currentElement.agree}
          onChange={() => setCurrentElement((prev) => ({ ...prev, agree: !prev.agree }))}
        />
        <Typography size="small" ml={2}>
          By submitting your contact information you agree to our{' '}
          <Link onClick={() => openModalTerms(true)} className="hover">
            terms and conditions
          </Link>
          .
        </Typography>
      </Box>

      {(!currentElement.id || showSave) && (
        <Box mt={5} display="flex">
          <ButtonSecondary
            fullWidth
            onClick={() => save()}
            disabled={!currentElement.agree || loading}
          >
            Save
          </ButtonSecondary>
          &nbsp;
          {!loading && !currentElement.id && (
            <ButtonCancel fullWidth onClick={() => cancel()}>
              Cancel
            </ButtonCancel>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormFinish;
