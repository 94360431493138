import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import Colors from '../../constants/Colors';

export default function MIcon({ icon, color, styles, size, ...props }) {
  return (
    <Icon {...props} style={{ fontSize: size, color, ...styles }}>
      {icon}
    </Icon>
  );
}

MIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object,
};

MIcon.defaultProps = {
  icon: null,
  color: Colors.secondary.solid,
  size: 20,
  styles: {},
};
