import React from 'react';

import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { v4 as uuidv4 } from 'uuid';
import TabCourt from './TabCourt';
import FormBasket from './Basket/Form';
import BasketCourtList from './Basket/List';
import FormVolleyball from './Volleyball/Form';
import VolleyballCourtList from './Volleyball/List';
import FormPickleball from './Pickleball/Form';
import PickleballCourtList from './Pickleball/List';

export const LayoutTypes = {
  basketball: {
    id: uuidv4(),
    value: 'basketball',
    label: 'Basketball',
    form: (props) => <FormBasket {...props} />,
    list: (props) => <BasketCourtList {...props} />,
  },
  volleyball: {
    id: uuidv4(),
    value: 'volleyball',
    label: 'Volleyball',
    form: (props) => <FormVolleyball {...props} />,
    list: (props) => <VolleyballCourtList {...props} />,
  },
  pickleball: {
    id: uuidv4(),
    value: 'pickleball',
    label: 'Pickleball',
    form: (props) => <FormPickleball {...props} />,
    list: (props) => <PickleballCourtList {...props} />,
  },
};

const Courts = () => {
  const mb = 2;
  return (
    <Box mb={10}>
      <Typography mx={3} mb={mb} font="regular" size="xSmall">
        Select the cross courts you would like in your design.
      </Typography>

      {Object.keys(LayoutTypes).map((type, index) => (
        <TabCourt key={index.toString()} tab={LayoutTypes[type]} />
      ))}
    </Box>
  );
};

export default Courts;
