import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress as Progress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const LinearProgress = (props) => {
  return (
    <Box display="flex" alignItems="center" flexGrow={1}>
      <Box width="100%" mr={1}>
        <Progress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgress.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default LinearProgress;
