/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Dialog, makeStyles } from '@material-ui/core';
import { Colors } from 'core/constants';

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'baseline', // default center
  },
  box: {
    borderRadius: 10,
    backgroundColor: Colors.secondary.solid,
  },
});

const ModalPreview = ({ close, imageBase64 }) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        classes={{ scrollPaper: classes.scrollPaper, paper: classes.box }}
        fullWidth
        maxWidth="md"
        open
        onClose={close}
      >
        <Box p={1} style={{ borderRadius: 10 }}>
          <Box p={5}>
            <img src={imageBase64} style={{ width: '100%' }} />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalPreview;
