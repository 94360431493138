/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Card } from '@material-ui/core';

import CFCFinal from 'assets/images/patters/CFC-final.jpg';
import covingtonFinal from 'assets/images/patters/covington-final.jpg';
import fosterFinal from 'assets/images/patters/foster-final.jpg';
import ponderFinal from 'assets/images/patters/Ponder-final.jpg';
import stoutFinal from 'assets/images/patters/STOUT-final.jpg';

const Patters = () => {
  const cardStyles = { width: '100%' };

  const CardProject = ({ img }) => (
    <Box display="flex" alignItems="center" m={1}>
      <Card style={cardStyles} className="hover">
        <Box p={10} display="flex" alignItems="center">
          <img src={img} alt="GymFloorDesigns Project" style={{ width: '100%' }} />
        </Box>
      </Card>
    </Box>
  );

  return (
    <>
      <Box flex={1}>
        <Box display="flex">
          <CardProject img={CFCFinal} />

          <CardProject img={covingtonFinal} />

          <CardProject img={fosterFinal} />

          <CardProject img={ponderFinal} />

          <CardProject img={stoutFinal} />
        </Box>
      </Box>
    </>
  );
};

export default Patters;
