import React, { useContext } from 'react';
import { Box, Paper } from '@material-ui/core';
import { DrawContext } from 'core/contexts';
import { ButtonPrimary } from 'core/ui/Button';
import MIcon from 'core/ui/MIcon';
import useStylesTopBar from './style';

const TopBar = () => {
  const classes = useStylesTopBar();
  const drawContext = useContext(DrawContext);

  return (
    <Paper className={classes.root} elevation={1}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box mx={1}>
          <ButtonPrimary
            variant="contained"
            onClick={() => {
              drawContext.zoom('in');
            }}
          >
            <MIcon icon="zoom_in" />
          </ButtonPrimary>
        </Box>
        <Box mx={1}>
          <ButtonPrimary variant="contained" onClick={() => drawContext.zoom('out')}>
            <MIcon icon="zoom_out" />
          </ButtonPrimary>
        </Box>
        <Box mx={1}>
          <ButtonPrimary
            variant="contained"
            onClick={() => drawContext.centerSelectedElement('vertical')}
          >
            <MIcon icon="vertical_align_center" />
          </ButtonPrimary>
        </Box>
        <Box mx={1}>
          <ButtonPrimary
            variant="contained"
            onClick={() => drawContext.centerSelectedElement('horizontal')}
          >
            <MIcon styles={{ transform: 'rotate(90deg)' }} icon="vertical_align_center" />
          </ButtonPrimary>
        </Box>
      </Box>
    </Paper>
  );
};

export default TopBar;
