/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';

import { DrawContext } from 'core/contexts';
import Typography from 'core/ui/Typography';
import TextField from 'core/ui/TextField';
import Radio from 'core/ui/Radio';
import Grid from 'core/ui/Grid';
import ColorPicker from 'core/ui/ColorPicker';
import Box from 'core/ui/Box';
import { ButtonCancel, ButtonSecondary } from 'core/ui/Button';
import Checkbox from 'core/ui/Checkbox';
import FontIcon from 'core/ui/FontIcon';
import { Colors } from 'core/constants';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { LayoutTypes } from '..';

const PointsLinesTypes = [
  {
    value: 'highschool',
    label: 'Highschool',
  },
  {
    value: 'ncaa',
    label: 'NCAA',
  },
  {
    value: 'nba',
    label: 'NBA',
  },
];

const FormBasket = ({ current, callback }) => {
  const { saveCourt, courts } = useContext(DrawContext);
  const [showSave, setShowSave] = useState(false);
  const defaultColor = '#ffffff';
  const dispatch = useDispatch();

  const mainCourt = courts.find((x) => !!x.main);

  const [currentElement, setCurrentElement] = useState({
    layoutType: LayoutTypes.basketball.value,
    name: 'COURT',
    length: 84,
    width: 50,
    boundaryColor: defaultColor,

    main: !mainCourt,

    highschool: false,
    nCAA: false,
    bBA: false,

    highschoolBoundaryColor: defaultColor,
    ncaaBoundaryColor: defaultColor,
    nbaBoundaryColor: defaultColor,

    // OPTIONS
    breakBorder: false,

    baseLineWidth: 0,
    sideLineWidth: 0,
    marginColor: '#000000',

    showOutline: false,
    outlineColor: '#FFEFD6',

    midCourt: {
      base: null,
      color: null,
      stainColor: null,
    },

    threePoint: {
      base: null,
      color: null,
      stainColor: null,
    },

    topOfKey: {
      base: null,
      color: null,
      stainColor: null,
    },

    bottomOfKey: {
      base: null,
      color: null,
      stainColor: null,
    },

    center: {
      base: null,
      color: null,
      stainColor: null,
    },
  });

  useEffect(() => {
    if (current) {
      setCurrentElement({
        ...current,
      });
    }
  }, [current]);

  useEffect(() => {
    setShowSave(true);
  }, [currentElement]);

  const validate = () => {
    if (!currentElement.highschool && !currentElement.nba && !currentElement.ncaa) {
      dispatch(notifications.warning('Select the 3 Point options'));
      return false;
    }

    return true;
  };

  const save = () => {
    if (!validate()) {
      return;
    }
    setShowSave(false);
    saveCourt(currentElement);
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    if (currentElement.main) {
      setCurrentElement((prev) => ({ ...prev, layoutCross: false }));
    }
  }, [currentElement.main]);

  const mb = 2;
  return (
    <Box mb={3}>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, name: v }))}
          value={currentElement.name}
          label="Name"
        />
      </Box>
      {(!mainCourt || mainCourt.id === currentElement.id) && (
        <Box mb={mb}>
          <Typography size="small">Main</Typography>
          <Box display="flex" justifyContent="center">
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Radio
                onClick={() => setCurrentElement((prev) => ({ ...prev, main: true }))}
                label="Yes"
                labelPosition="right"
                checked={!!currentElement.main}
              />
            </Box>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Radio
                onClick={() => setCurrentElement((prev) => ({ ...prev, main: false }))}
                label="No"
                labelPosition="right"
                checked={!currentElement.main}
              />
            </Box>
          </Box>
        </Box>
      )}

      <>
        <Box mb={mb}>
          <Typography size="small">Show Outline</Typography>
          <Box display="flex" justifyContent="center">
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Radio
                onClick={() => setCurrentElement((prev) => ({ ...prev, showOutline: true }))}
                label="Yes"
                labelPosition="right"
                checked={!!currentElement.showOutline}
              />
            </Box>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Radio
                onClick={() => setCurrentElement((prev) => ({ ...prev, showOutline: false }))}
                label="No"
                labelPosition="right"
                checked={!currentElement.showOutline}
              />
            </Box>
          </Box>
        </Box>
        {currentElement.showOutline && (
          <Box mb={mb}>
            <ColorPicker
              color={currentElement.outlineColor}
              setColor={(v) => setCurrentElement((prev) => ({ ...prev, outlineColor: v }))}
              label="Outline Color"
            />
          </Box>
        )}
      </>

      <Box mb={mb}>
        <Typography size="small">Layout</Typography>
        <Box display="flex" justifyContent="center">
          {!currentElement.main && (
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <FontIcon iconName="icon-cross-courts" size={25} color={Colors.secondary.solid} />
              <Radio
                onClick={() => setCurrentElement((prev) => ({ ...prev, layoutCross: true }))}
                label="Vertical"
                labelPosition="right"
                checked={!!currentElement.layoutCross}
              />
            </Box>
          )}
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FontIcon iconName="icon-court-layout" size={25} color={Colors.secondary.solid} />
            <Radio
              onClick={() => setCurrentElement((prev) => ({ ...prev, layoutCross: false }))}
              label="Horizontal"
              labelPosition="right"
              checked={!currentElement.layoutCross}
            />
          </Box>
        </Box>
      </Box>

      <Grid mb={mb} container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            onChange={(v) => setCurrentElement((prev) => ({ ...prev, length: v }))}
            type="number"
            value={currentElement.length}
            pattern="[0-9]*"
            required
            label="Boundary Length (ft)"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            onChange={(v) => setCurrentElement((prev) => ({ ...prev, width: v }))}
            type="number"
            value={currentElement.width}
            pattern="[0-9]*"
            required
            label="Boundary Width (ft)"
          />
        </Grid>
      </Grid>

      <Box mb={mb}>
        <ColorPicker
          color={currentElement.boundaryColor}
          setColor={(v) => setCurrentElement((prev) => ({ ...prev, boundaryColor: v }))}
          label="Boundary Color"
        />
      </Box>

      <>
        <Typography size="small">3 Point Options</Typography>

        <Box>
          {PointsLinesTypes.map((type, index) => (
            <Box key={index.toString()}>
              <Checkbox
                onClick={() =>
                  setCurrentElement((prev) => ({ ...prev, [type.value]: !prev[type.value] }))
                }
                label={type.label}
                labelPosition="right"
                checked={!!currentElement[type.value]}
              />

              {!!currentElement[type.value] && (
                <Box py={1}>
                  <ColorPicker
                    color={currentElement[`${type.value}BoundaryColor`]}
                    setColor={(v) =>
                      setCurrentElement((prev) => ({
                        ...prev,
                        [`${type.value}BoundaryColor`]: v,
                      }))
                    }
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </>

      {(!currentElement.id || showSave) && (
        <Box mt={5} display="flex">
          <ButtonSecondary fullWidth onClick={() => save()}>
            Save
          </ButtonSecondary>
          &nbsp;
          {!currentElement.id && (
            <ButtonCancel fullWidth onClick={() => callback()}>
              Cancel
            </ButtonCancel>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormBasket;
