import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { projectsActions as actions } from './index';

export function* getProjects() {
  try {
    const response = yield call(api.getProjects);
    const project = response.data;
    yield put(actions.getProjectsSuccess(project));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getProjectsError(getErrorMessage(error))),
    ]);
  }
}

export function* getProject({ uuid }) {
  try {
    const response = yield call(api.getProject, uuid);
    const project = response.data;
    yield put(actions.getProjectSuccess(project));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getProjectError(getErrorMessage(error))),
    ]);
  }
}

export function* postProject({ data, callback }) {
  try {
    const response = yield call(api.postProject, data);
    const project = response.data;
    yield put(actions.postProjectSuccess(project));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postProjectError(getErrorMessage(error))),
    ]);
  }
}
