import { makeStyles } from '@material-ui/core';
import { NAV_BAR_HEIGHT } from 'core/constants';

const useStylesRightBar = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    position: 'fixed',
    width: 350,
    borderRadius: 0,
    top: NAV_BAR_HEIGHT,
    right: 0,
    height: '100%',
    zIndex: 10,
  },
}));

export default useStylesRightBar;
