import { makeStyles } from '@material-ui/core';
import { Colors, NAV_BAR_HEIGHT } from 'core/constants';

const useStylesTopBar = makeStyles(() => ({
  root: {
    backgroundColor: Colors.secondary.white,
    // border: `solid 0.5px ${Colors.secondary.light}`,
    position: 'fixed',
    width: 400,
    height: 40,
    top: NAV_BAR_HEIGHT,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 5,
    zIndex: 10,
  },
}));

export default useStylesTopBar;
