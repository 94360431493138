/* eslint import/prefer-default-export: "off" */

import { put, select, delay } from 'redux-saga/effects';

import { notificationsActions as actions, notificationsSelectors as selectors } from './index';

export function* dismissNotification() {
  const notification = yield select(selectors.getNotification);
  yield delay(notification.autoDismiss * 1000);
  yield put(actions.dismiss());
}
