import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { useToasts } from 'react-toast-notifications';
import Axios from 'axios';

import { last } from 'core/utils/fp';
import Splash from 'core/ui/Splash';
import { API_GOOGLE_KEY } from 'core/env';
import { notificationsSelectors } from './modules/notifications';
import { appActions } from './modules/app';

const Main = ({ route: { routes } }) => {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(true);
  const getFonts = () => {
    Axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${API_GOOGLE_KEY}`).then(
      (result) => {
        dispatch(appActions.setGoogleFonts(result.data.items));
      },
    );
  };

  useEffect(() => {
    getFonts();
  }, []);

  const notifications = useSelector((state) => notificationsSelectors.getNotifications(state));

  const setNotification = useCallback(() => {
    const notification = last(notifications);

    if (notification) {
      addToast(notification.message, { appearance: notification.level });
    }
  }, [notifications]);

  useEffect(() => {
    setNotification();
  }, [setNotification, notifications]);

  return (
    <>
      {/* NOT RENDER ROUTES FROM LAYOUT IF NOT IS AUTHENTICATED */}
      {!loaded && (
        <>
          <Splash />
        </>
      )}
      {loaded && <>{renderRoutes(routes)}</>}
    </>
  );
};

Main.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }).isRequired,
  route: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    routes: PropTypes.array,
  }).isRequired,
};

export default Main;
