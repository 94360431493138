import React, { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { renderRoutes } from 'react-router-config';
import Navbar from 'components/Nav/Navbar';
import { matchPath, useLocation } from 'react-router';

export const LayoutContext = createContext();
const drawerWidth = 250;
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
  },
}));

function Layout({ route: { routes } }) {
  const classes = useStyles();
  const location = useLocation();
  const [titleBar, setTitleBar] = useState('');
  const [drawer, setDrawer] = useState(false);
  const [currentRoute, setCurrentRoute] = useState({});

  const getRoute = useCallback(
    (arrayRoutes) => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < arrayRoutes.length; index++) {
        const r = arrayRoutes[index];
        const match = matchPath(location.pathname, r);

        if (match) {
          return r;
        }

        if (r.routes) {
          return getRoute(r.routes);
        }
      }
      return null;
    },
    [location.pathname],
  );

  useEffect(() => {
    const route = getRoute(routes);
    setCurrentRoute(route);
  }, [routes, getRoute]);

  return (
    <div className={classes.root}>
      <Navbar currentRoute={currentRoute} />
      <CssBaseline />
      <LayoutContext.Provider
        value={{
          drawer,
          setDrawer,
          titleBar,
          drawerWidth,
          setTitleBar,
        }}
      >
        <main className={classes.content}>
          <div>{renderRoutes(routes)}</div>
        </main>
      </LayoutContext.Provider>
    </div>
  );
}

Layout.propTypes = {
  route: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    routes: PropTypes.array,
  }).isRequired,
};

export default Layout;
