import woodTexture from 'assets/images/texture-wood.jpg';
import woodTextureV from 'assets/images/texture-wood-v.jpg';

import woodTextureWhite from 'assets/images/texture-wood-white.jpg';
import woodTextureWhiteV from 'assets/images/texture-wood-white-v.jpg';

import woodTextureLightBrown from 'assets/images/texture-wood-light-brown.jpg';
import woodTextureLightBrownV from 'assets/images/texture-wood-light-brown-v.jpg';

import woodTextureDarkBrown from 'assets/images/texture-wood-dark-brown.jpg';
import woodTextureDarkBrownV from 'assets/images/texture-wood-dark-brown-v.jpg';

import woodTextureGrey from 'assets/images/texture-wood-grey.jpg';
import woodTextureGreyV from 'assets/images/texture-wood-grey-v.jpg';

import woodTextureBlack from 'assets/images/texture-wood-black.jpg';
import woodTextureBlackV from 'assets/images/texture-wood-black-v.jpg';

export default [
  {
    name: 'None',
    value: null,
    texture: woodTexture,
    textureV: woodTextureV,
    color: null,
  },
  {
    name: 'White',
    value: 'white',
    color: 'rgba(255, 255, 255, 0.6)',
    texture: woodTextureWhite,
    textureV: woodTextureWhiteV,
  },
  {
    name: 'Light Brown',
    value: 'light_brown',
    color: 'rgba(172, 142, 72, 0.6)',
    texture: woodTextureLightBrown,
    textureV: woodTextureLightBrownV,
  },
  {
    name: 'Dark Brown',
    value: 'dark_brown',
    color: 'rgba(90, 52, 1, 0.6)',
    texture: woodTextureDarkBrown,
    textureV: woodTextureDarkBrownV,
  },
  {
    name: 'Grey',
    value: 'grey',
    color: 'rgba(144, 140, 140, 0.6)',
    texture: woodTextureGrey,
    textureV: woodTextureGreyV,
  },
  {
    name: 'Black',
    value: 'black',
    color: 'rgba(0, 0, 0, 0.6)',
    texture: woodTextureBlack,
    textureV: woodTextureBlackV,
  },
];
