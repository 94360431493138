import usePosition from 'components/Draw/position';
import { fabric } from 'fabric';
import WebFont from 'webfontloader';

export default () => {
  const { centerCanvas, positionElement } = usePosition();

  const drawText = ({ canvas, textElement, room, position, rotation }) => {
    WebFont.load({
      google: {
        families: [textElement.font],
      },
      fontloading: () => {
        setTimeout(() => {
          const myText = new fabric.Text(textElement.text, {
            underline: false,
            overline: false,
            fontFamily: textElement.font,
            fill: textElement.color,
            fontSize: textElement.fontSize || 30,
            lockUniScaling: true,
            lockScalingX: true,
            lockScalingY: true,
          });
          myText.id = textElement.id;
          if (position) {
            positionElement({ element: myText, room, position });
          } else {
            centerCanvas(canvas, myText);
          }
          if (rotation) {
            myText.angle = rotation.angle;
          }
          if (textElement.outlineColor && textElement.outlineWidth) {
            myText.strokeWidth = Number(textElement.outlineWidth);
            myText.stroke = textElement.outlineColor;
          }
          myText.zIndex = 40;

          canvas.add(myText);
          canvas.renderAll();
          canvas.renderAll();
        }, 300);
      },
    });
  };

  return { drawText };
};
