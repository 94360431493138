import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import imgLoadingIntro from 'assets/images/loader-2.gif';
import { Dialog } from '@material-ui/core';
import Box from '../Box';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 2000,
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  img: {
    maxWidth: 600,
  },
}));

export default function Loader({ message }) {
  const classes = useStyles();

  return (
    <Dialog open className={classes.root}>
      <Box
        p={10}
        pb={5}
        pt={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <img src={imgLoadingIntro} alt="live" style={{ width: 100 }} />
        </Box>
        <Typography align="right" size="small" font="medium" color="inherit">
          {message}
        </Typography>
      </Box>
    </Dialog>
  );
}

Loader.defaultProps = {
  message: 'Loading...',
};

Loader.propTypes = {
  message: PropTypes.string,
};
