const Colors = {
  primary: {
    solid: '#EC4242',
    dark: '#870404',
  },
  secondary: {
    solid: '#454444',
    solidHover: '#545353',
    light: '#626060AF',
    xLight: '#e9e9e9',
    xxLight: '#E8E8E8',
    white: '#fff',
    whiteHover: '#B4B4B4',
    buttonAdd: '#72E272',
    buttonRemove: '#EC4141',
  },
  panel: {
    bg: '#F3F3F3',
  },
};

export default Colors;
