/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/require-default-props */
import React from 'react';
import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { InputLabel } from '@material-ui/core';

const SelFileProject = ({ setFile }) => {
  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const file = target.files[i];

        fetch(URL.createObjectURL(file))
          .then((r) => r.text())
          .then((text) => {
            setFile({
              text,
              name: file.name,
            });
          });
      }
    }
  };

  return (
    <Box style={{ position: 'relative', zIndex: 3 }}>
      <InputLabel htmlFor="icon-button-file" className="hover">
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography font="bold" size="medium" color="secondary">
            Import File
          </Typography>
        </Box>
      </InputLabel>
      <input
        color="primary"
        type="file"
        onChange={handleCapture}
        id="icon-button-file"
        style={{ display: 'none' }}
        accept=".fosterp"
        multiple
      />
    </Box>
  );
};

SelFileProject.propTypes = {
  setFile: propTypes.func,
};

export default SelFileProject;
