export const svgHalfCircle = 'M1,61.38,1,48.65a48,48,0,0,1,96,0l0,12.73';

export const svgThreePointBottom =
  'M23,84.8h144v228H23V84.8z M13.9,217.8h8v12h-8V217.8z M168.1,216.8h8v12h-8V216.8z M13.9,178.8h8v2h-8V178.8z   M168.1,178.8h8v2h-8V178.8z M13.9,140.8h8v2h-8V140.8z M13.9,102.8h8v2h-8V102.8z M168.1,140.8h8v2h-8V140.8z M168.1,102.8h8v2h-8  V102.8z M168,218.1h4v7.4h-4V218.1z M170.1,218.1h4v7.4h-4V218.1z M168,220.8h4v7.4h-4V220.8z M170.1,220.8h4v7.4h-4V220.8z   M170.1,219h4v7.4h-4V219z M168,217.9h4v7.4h-4V217.9z M13.9,217.8h8v12h-8V217.8z M13.9,219.1h4v7.4h-4V219.1z M16,219.1h4v7.4h-4  V219.1z M13.9,221.8h4v7.4h-4V221.8z M16,221.8h4v7.4h-4V221.8z M16,220h4v7.4h-4V220z M13.9,218.9h4v7.4h-4V218.9z';
export const svgThreePointBottomOutline =
  'M154.17,230.12H8.17V.12h146Zm-144-2h142V2.12h-142Zm-2.05-82v-12h-8v12Zm154.1,0v-12h-8v12Zm-154.1-48v-2h-8v2Zm154.1,0v-2h-8v2Zm-154.1-38v-2h-8v2Zm0-38v-2h-8v2Zm154.1,38v-2h-8v2Zm0-38v-2h-8v2Z';

export const svgThreePointTop = 'M1,73a72,72,0,0,1,144,0';
export const svgThreePointTopOutline = 'M146.12,73.12h-2a71,71,0,0,0-142,0h-2a73,73,0,0,1,146,0Z';

export const circleSvg =
  'M311.497,143.138  c145.724,0,263.856,118.132,263.856,263.856c0,145.724-118.133,263.856-263.856,263.856S47.641,552.718,47.641,406.994  C47.641,261.27,165.773,143.138,311.497,143.138z';
export const circleOutlineSvg =
  'M73.12,146.12a73,73,0,1,1,73-73A73.08,73.08,0,0,1,73.12,146.12Zm0-144a71,71,0,1,0,71,71A71.09,71.09,0,0,0,73.12,2.12Z';

export const HcSvgOutline =
  'M238.12,299.26A237.31,237.31,0,0,1,69.84,230.07,233.86,233.86,0,0,1,.12,63L.36.13h2L2.12,63c0,129.17,105.87,234.26,236,234.26s236-105.09,236-234.27L474,.13h2L476.12,63a233.85,233.85,0,0,1-69.71,167.08A237.31,237.31,0,0,1,238.12,299.26Z';

export const HcSvgPath =
  'M482.9,14.93L483,77.8c0,129.94-106.11,235.27-237,235.27S9,207.74,9,77.8l0.23-62.86';

export const NcaaSvgOutline =
  'M261.34,339.81h0c-61.95,0-127.16-25.67-178.91-70.39-49.18-42.5-79.94-97-82.27-145.73L1.3.13l2,0L2.12,123.66c2.31,48.15,32.81,102.09,81.59,144.25,51.39,44.41,116.12,69.89,177.6,69.9h0c60,0,123.8-24.23,175-66.47,50.22-41.43,80.75-93.23,83.77-142.13L519.45.14h2l.67,129.13c-1.54,25-10.06,50.61-25.33,76.09-14.52,24.23-35,47.59-59.16,67.53C386.06,315.41,321.81,339.81,261.34,339.81Z';
export const NcaaSvgPath =
  'M520.33,0,521,129.14c-6.26,101.5-131.48,209.56-259.82,209.54C128,338.66,5.78,223.38,1,123.52L2.17,0';

export const NbaSvgPath =
  'M9.17,14.25L9,196.31c41.17,111.62,142.43,188.45,265.1,188.45c120.29,0,222.39-77.36,262.81-186.92L537,14.26';

export const NbaSvgOutline =
  'M266.22,372.63c-59.6,0-116.21-18.18-163.69-52.56C56.13,286.48,20.74,239.26.19,183.53l-.07-.17v-.18L.3.13h2L2.12,183c42.12,114,145.77,187.63,264.1,187.63,117.28,0,220-73,261.82-186.1L528.12.13h2v1L530,184.89l-.07.17c-20.18,54.7-55.38,101.39-101.8,135A274.44,274.44,0,0,1,266.22,372.63Z';
