import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Colors } from 'core/constants';
import { Box } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: -1,
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const FDialog = ({ children, open, close, title, maxWidth, ...props }) => (
  <div>
    <Dialog maxWidth={maxWidth} onClose={close} open={open} {...props}>
      <Box style={{ backgroundColor: Colors.panel.bg }}>
        {title && <DialogTitle onClose={close}>{title}</DialogTitle>}
        <>{children}</>
      </Box>
    </Dialog>
  </div>
);

FDialog.propTypes = {
  children: propTypes.node.isRequired,
  open: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  title: propTypes.string,
  maxWidth: propTypes.string,
};

FDialog.defaultProps = {
  title: '',
  maxWidth: 'md',
};

export default FDialog;
