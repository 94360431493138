import { makeStyles } from '@material-ui/core';
import { Colors } from 'core/constants';
import FosterHeader from 'assets/images/floor-header.jpg';

const useStylesHome = makeStyles((theme) => ({
  imageHeader: {
    width: '100%',
    backgroundImage: `url(${FosterHeader})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  headerContainer: {
    paddingTop: '20%',
    position: 'relative',
  },
  headerContent: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
  },
  buttonLets: {
    backgroundColor: Colors.secondary.white,
    height: 100,
    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
    borderRadius: 10,
  },
  title: {
    fontSize: 50,
    fontFamily: 'HelveticaNeue-Bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

export default useStylesHome;
