import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import { MenuItem, Select, Paper } from '@material-ui/core';
import Box from '../Box';
import Typography from '../Typography';

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: 20,
    position: 'relative',
    backgroundColor: '#fff',
    width: '100%',
    fontSize: 12,
    paddingLeft: 5,
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: 20,
      borderColor: '#80bdff',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  select: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
  },
  placeHolder: {
    color: '#C7C7CD',
  },
}));

export default function SelectBox({
  options,
  placeholder,
  value,
  label,
  error,
  setValue,
  required,
}) {
  const classes = useStyles();

  return (
    <div>
      {label !== '' && (
        <Box display="flex">
          <Typography size="label">{label}</Typography>
          {required && (
            <Typography ml={1} color="primary">
              *
            </Typography>
          )}
        </Box>
      )}
      <Paper
        className={`${error && value === 'undefined' ? 'input-paper-error' : ''} ${classes.root}`}
      >
        <Select
          className={`${classes.select} ${value === 'undefined' ? classes.placeHolder : ''}`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          input={<BootstrapInput />}
        >
          {placeholder && (
            <MenuItem disabled value={undefined}>
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((x) => {
            return (
              <MenuItem key={x} value={`${x}`}>
                {x}
              </MenuItem>
            );
          })}
        </Select>
      </Paper>
    </div>
  );
}

SelectBox.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  setValue: PropTypes.func,
  required: PropTypes.bool,
};

SelectBox.defaultProps = {
  label: '',
  options: [],
  value: '',
  placeholder: '',
  error: null,
  setValue: null,
  required: false,
};
