/* eslint-disable import/no-extraneous-dependencies */
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { withStyles } from '@material-ui/core/styles';
import { Button as MuiButton } from '@material-ui/core';
import Colors from '../../constants/Colors';
import Box from '../Box';

const Button = styled(MuiButton)(spacing);

export const ButtonPrimary = withStyles(() => ({
  root: {
    color: Colors.secondary.solid,
    backgroundColor: Colors.secondary.white,
    '&:hover': {
      backgroundColor: Colors.secondary.whiteHover,
    },
    borderRadius: 10,
    padding: 5,
    paddingRight: 5,
    paddingLeft: 5,
  },
}))(Button);

export const ButtonSecondary = withStyles(() => ({
  root: {
    color: Colors.secondary.white,
    backgroundColor: Colors.secondary.solid,
    '&:hover': {
      backgroundColor: Colors.secondary.light,
    },
    borderRadius: 5,
    padding: 5,
    paddingRight: 5,
    paddingLeft: 5,
  },
}))(Button);

export const ButtonCancel = withStyles(() => ({
  root: {
    color: Colors.secondary.white,
    backgroundColor: Colors.secondary.buttonRemove,
    '&:hover': {
      backgroundColor: Colors.secondary.buttonRemove,
    },
    borderRadius: 5,
    padding: 5,
    paddingRight: 5,
    paddingLeft: 5,
  },
}))(Button);

export const ButtonAddCourt = withStyles(() => ({
  root: {
    color: Colors.secondary.solid,
    backgroundColor: Colors.secondary.buttonAdd,
    '&:hover': {
      backgroundColor: Colors.secondary.buttonAdd,
      opacity: 0.9,
    },
    borderRadius: 5,
    padding: 5,
    paddingRight: 5,
    paddingLeft: 5,
  },
}))(Button);

export const ButtonSelect = withStyles(() => ({
  root: {
    color: Colors.secondary.solid,
    backgroundColor: Colors.secondary.buttonAdd,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: Colors.secondary.buttonAdd,
      opacity: 0.9,
      cursor: 'pointer',
    },
    borderRadius: 5,
    padding: 2,
  },
}))(Box);

export const ButtonRemoveCourt = withStyles(() => ({
  root: {
    color: Colors.secondary.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary.buttonRemove,
    '&:hover': {
      backgroundColor: Colors.secondary.buttonRemove,
      opacity: 0.9,
      cursor: 'pointer',
    },
    borderRadius: 5,
    padding: 2,
  },
}))(Box);

export const ButtonTopBar = withStyles(() => ({
  root: {
    // background: 'rgb(204,204,204)',
    // background: 'linear-gradient(90deg, rgba(213,213,213,1) 0%, rgba(204,204,204,1) 48%, rgba(213,213,213,1) 100%)',
    // '&:hover': {
    //   background: 'linear-gradient(90deg, rgba(204,204,204,1) 0%, rgba(207,207,207,1) 48%, rgba(204,204,204,1) 100%)',
    // },
    border: 0,
    borderRadius: 5,
    padding: 0,
    height: 30,
  },
}))(Button);

export default Button;
