/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';

import { DrawContext } from 'core/contexts';
import TextField from 'core/ui/TextField';
import ColorPicker from 'core/ui/ColorPicker';
import Box from 'core/ui/Box';
import { ButtonCancel, ButtonSecondary } from 'core/ui/Button';
import FontPicker from 'core/ui/FontPicker';
import Typography from 'core/ui/Typography';
import WebFont from 'webfontloader';
import Paper from 'core/ui/Paper';

const FormText = ({ current, callback }) => {
  const { saveText } = useContext(DrawContext);
  const [showSave, setShowSave] = useState(false);
  const [currentElement, setCurrentElement] = useState({
    color: '#000',
    font: 'Roboto',
    fontSize: 30,
    outlineColor: null,
  });

  useEffect(() => {
    if (current) {
      setCurrentElement((prev) => ({
        ...prev,
        ...current,
      }));
    }
  }, [current]);

  useEffect(() => {
    setShowSave(true);
  }, [currentElement]);

  const save = () => {
    setShowSave(false);
    saveText(currentElement);
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: [currentElement.font],
      },
      fontloading: () => {},
    });
  }, [currentElement.font]);

  const mb = 2;
  return (
    <Box mb={3}>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, text: v }))}
          value={currentElement.text || ''}
          label="Text"
        />
      </Box>
      <Box mb={mb}>
        <ColorPicker
          color={currentElement.color}
          setColor={(v) => setCurrentElement((prev) => ({ ...prev, color: v }))}
          label="Text Color"
        />
      </Box>
      <Box mb={mb}>
        <ColorPicker
          color={currentElement.outlineColor}
          setColor={(v) => setCurrentElement((prev) => ({ ...prev, outlineColor: v }))}
          label="Outline Color"
        />
      </Box>
      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, outlineWidth: v }))}
          value={currentElement.outlineWidth || ''}
          label="Outline Width"
          type="number"
          pattern="[0-9]*"
        />
      </Box>

      <Box mb={mb}>
        <TextField
          onChange={(v) => setCurrentElement((prev) => ({ ...prev, fontSize: v }))}
          type="number"
          value={currentElement.fontSize || 0}
          pattern="[0-9]*"
          required
          label="Font Size"
        />
      </Box>

      <FontPicker
        font={currentElement.font}
        label="Font Family"
        setFont={(f) => setCurrentElement((prev) => ({ ...prev, font: f }))}
      />

      <Paper p={3} m={3}>
        <Typography align="center" size="medium" style={{ fontFamily: currentElement.font }}>
          {currentElement.text}
        </Typography>
      </Paper>

      {(!currentElement.id || showSave) && (
        <Box mt={5} display="flex">
          <ButtonSecondary fullWidth onClick={() => save()}>
            Save
          </ButtonSecondary>
          &nbsp;
          {!currentElement.id && (
            <ButtonCancel fullWidth onClick={() => callback()}>
              Cancel
            </ButtonCancel>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormText;
