/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';

import { DrawContext } from 'core/contexts';
import Typography from 'core/ui/Typography';
import TextField from 'core/ui/TextField';
import Radio from 'core/ui/Radio';
import Grid from 'core/ui/Grid';
import ColorPicker from 'core/ui/ColorPicker';
import Box from 'core/ui/Box';
import { ButtonCancel, ButtonSecondary } from 'core/ui/Button';
import Divider from 'core/ui/Divider';
import OptionAreaColor from './OptionAreaColor';

const FormCourtOptionsBasket = ({ current, callback }) => {
  const { saveCourt, room } = useContext(DrawContext);
  const [showSave, setShowSave] = useState(false);
  const [currentElement, setCurrentElement] = useState({});

  useEffect(() => {
    if (current) {
      setCurrentElement((prev) => ({
        ...prev,
        ...current,
      }));
    }
  }, [current]);

  useEffect(() => {
    setShowSave(true);
  }, [currentElement]);

  const save = () => {
    setShowSave(false);
    saveCourt(currentElement);
    if (callback) {
      callback();
    }
  };

  const mb = 2;
  return (
    <Box mb={3}>
      <Grid mb={mb} container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            onChange={(v) => setCurrentElement((prev) => ({ ...prev, baseLineWidth: v }))}
            type="number"
            value={currentElement.baseLineWidth || ''}
            pattern="[0-9]*"
            required
            label="Baseline Border (ft)"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            onChange={(v) => setCurrentElement((prev) => ({ ...prev, sideLineWidth: v }))}
            type="number"
            value={currentElement.sideLineWidth || ''}
            pattern="[0-9]*"
            required
            label="Sideline Border (ft)"
          />
        </Grid>
      </Grid>
      <Box mb={mb}>
        <ColorPicker
          color={currentElement.marginColor}
          setColor={(v) => setCurrentElement((prev) => ({ ...prev, marginColor: v }))}
          label="Border Color"
        />
      </Box>
      <Typography font="bold" size="medium">
        Court Areas
      </Typography>
      <Divider />
      <Typography mt={2} size="label">
        Mid-Court
      </Typography>
      <OptionAreaColor
        option={currentElement.midCourt || {}}
        setOption={setCurrentElement}
        object="midCourt"
        baseRoom={room.base}
      />
      <Divider mx={5} my={1} />
      <Typography mt={2} size="label">
        Top of Key
      </Typography>
      <OptionAreaColor
        option={currentElement.topOfKey || {}}
        setOption={setCurrentElement}
        object="topOfKey"
        baseRoom={room.base}
      />
      <Divider mx={5} my={1} />
      <Typography mt={2} size="label">
        Bottom of Key (Free Throw Lane)
      </Typography>
      <OptionAreaColor
        option={currentElement.bottomOfKey || {}}
        setOption={setCurrentElement}
        object="bottomOfKey"
        baseRoom={room.base}
      />
      <Divider mx={5} my={1} />
      <Typography mt={2} size="label">
        3 Point Line
      </Typography>
      <OptionAreaColor
        option={currentElement.threePoint || {}}
        setOption={setCurrentElement}
        object="threePoint"
        baseRoom={room.base}
      />
      <Divider mx={5} my={1} />
      <Typography size="label">Center Cicle</Typography>
      <OptionAreaColor
        option={currentElement.center || {}}
        setOption={setCurrentElement}
        object="center"
        baseRoom={room.base}
      />

      {currentElement.main && (
        <Box mb={mb} mt={3}>
          <Typography mb={1} font="bold" size="small">
            Do you want cross courts to break the border?
          </Typography>
          <Radio
            onClick={() => setCurrentElement((prev) => ({ ...prev, breakBorder: true }))}
            label="Yes"
            labelPosition="right"
            checked={!!currentElement.breakBorder}
          />

          <Radio
            onClick={() => setCurrentElement((prev) => ({ ...prev, breakBorder: false }))}
            label="No"
            labelPosition="right"
            checked={!currentElement.breakBorder}
          />
        </Box>
      )}

      {(!currentElement.id || showSave) && (
        <Box mt={5} display="flex">
          <ButtonSecondary fullWidth onClick={() => save()}>
            Save
          </ButtonSecondary>
          &nbsp;
          {!currentElement.id && (
            <ButtonCancel fullWidth onClick={() => callback()}>
              Cancel
            </ButtonCancel>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormCourtOptionsBasket;
