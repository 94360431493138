/* eslint-disable no-param-reassign */
import usePosition from 'components/Draw/position';
import { fabric } from 'fabric';

export default () => {
  const { centerCanvas, positionElement } = usePosition();

  const drawImage = ({ canvas, imageElement, position, room, rotation, scaling }) => {
    fabric.Image.fromURL(
      imageElement.image.Location || imageElement.image.location,
      (oImg) => {
        oImg.id = imageElement.id;
        oImg.crossOrigin = 'Anonymous';
        oImg.zIndex = 45;
        if (position) {
          positionElement({ element: oImg, room, position });
        } else {
          centerCanvas(canvas, oImg);
        }

        if (scaling) {
          oImg.scaleToWidth(scaling.width);
          oImg.scaleToHeight(scaling.height);
        }

        if (rotation) {
          oImg.angle = rotation.angle;
        }

        canvas.add(oImg);
      },
      {
        crossOrigin: 'Anonymous',
      },
    );
  };

  return { drawImage };
};
