import React, { useState } from 'react';
import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import { SketchPicker } from 'react-color';
import { Colors } from 'core/constants';
import Collapse from '@material-ui/core/Collapse';
import FDialog from '../Dialog';
import TextField from '../TextField';
import { ButtonPrimary, ButtonSecondary } from '../Button';
import Typography from '../Typography';
import useStylesColorPicker from './style';
import MIcon from '../MIcon';
import colorOptions from './syntheticColorsOptions';
import roomColorOptions from './syntheticRoomColorsOptions';
import Grid from '../Grid';
import Radio from '../Radio';

export default function ColorPicker({ setColor, color, room, label }) {
  const classes = useStylesColorPicker();
  const [modal, setModal] = useState({
    open: false,
    fill: color,
  });

  const [showColors, setShowColors] = useState(false);

  const toggleModal = (value) => {
    setModal((prev) => ({ ...prev, fill: color, open: value }));
  };

  const confirmColor = () => {
    setColor(modal.fill);
    toggleModal(false);
  };

  return (
    <>
      <Box flex={1}>
        <Typography font="medium" size="label" color="secondary">
          {label}
        </Typography>
        <Box display="flex">
          <Box className={classes.inputColor}>
            <TextField value={color || ''} disabled onClick={() => toggleModal(true)} />
          </Box>
          <Box
            onClick={() => toggleModal(true)}
            className={classes.boxPreviewColor}
            style={{ backgroundColor: color }}
            mx={3}
          >
            &nbsp;
          </Box>
          <Box flexGrow={1}>
            <ButtonSecondary onClick={() => setShowColors(!showColors)}>
              Color Options
              <MIcon
                icon={showColors ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                color={Colors.secondary.white}
              />
            </ButtonSecondary>
          </Box>
        </Box>
      </Box>
      <Box>
        <Collapse in={showColors}>
          <Grid my={3} container spacing={3}>
            {(room ? roomColorOptions : colorOptions).map((c, index) => (
              <Grid item xs={6} key={index.toString()}>
                <Box
                  display="flex"
                  alignItems="center"
                  className="hover"
                  onClick={() => setColor(c.value)}
                >
                  <Radio size="small" checked={color === c.value} />
                  <Box
                    mx={1}
                    className={classes.boxPreviewOptionsColor}
                    style={{ backgroundColor: c.value }}
                  >
                    &nbsp;
                  </Box>
                  <Typography font="medium" size="xxSmall" color="secondary">
                    {c.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Box>
      <FDialog maxWidth="xs" open={modal.open} close={() => toggleModal(false)}>
        <Box
          display="flex"
          flexDirection="column"
          style={{ backgroundColor: Colors.secondary.solid }}
          p={5}
        >
          <SketchPicker
            styles={{ backgroundColor: 'red' }}
            color={modal.fill || ''}
            onChangeComplete={({ hex }) => setModal((prev) => ({ ...prev, fill: hex }))}
          />
          <Box display="flex" mt={5}>
            <Box flexGrow={1} mr={3}>
              <ButtonPrimary
                fullWidth
                variant="contained"
                onClick={() => {
                  toggleModal(false);
                }}
              >
                Cancel
              </ButtonPrimary>
            </Box>
            <Box flexGrow={1} ml={3}>
              <ButtonPrimary fullWidth variant="contained" onClick={() => confirmColor()}>
                Save
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </FDialog>
    </>
  );
}

ColorPicker.propTypes = {
  color: propTypes.string,
  setColor: propTypes.func.isRequired,
  label: propTypes.string,
  room: propTypes.bool,
};

ColorPicker.defaultProps = {
  label: '',
  color: '',
  room: false,
};
