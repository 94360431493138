import useWindowSize from 'core/utils/useWindowSize';

export default () => {
  const SCALE_FACTOR = 1.3;
  const zoomMax = 23;
  const size = useWindowSize();

  const zoomIn = (canvas) => {
    if (canvas.getZoom().toFixed(5) > zoomMax) {
      console.log('zoomIn: Error: cannot zoom-in anymore');
      return;
    }

    canvas.setZoom(canvas.getZoom() * SCALE_FACTOR);
    canvas.setHeight(canvas.getHeight() * SCALE_FACTOR);
    canvas.setWidth(canvas.getWidth() * SCALE_FACTOR);
    canvas.renderAll();
  };

  const zoomOut = (canvas) => {
    if (canvas.getZoom().toFixed(5) <= -5) {
      console.log('zoomOut: Error: cannot zoom-out anymore');
      return;
    }

    canvas.setZoom(canvas.getZoom() / SCALE_FACTOR);
    canvas.setHeight(canvas.getHeight() / SCALE_FACTOR);
    canvas.setWidth(canvas.getWidth() / SCALE_FACTOR);
    canvas.renderAll();
  };

  // Zoom By Mouse Coordinates
  const zoomByMouseCoords = (canvas, direction, scrollRef = null) => {
    if (!direction) return;

    if (direction.toLowerCase() === 'in') {
      if (canvas.getZoom().toFixed(5) > zoomMax) {
        console.log('zoomByMouseCoords: Error: cannot zoom-in anymore');
        return;
      }
      zoomIn(canvas);
    }
    if (direction.toLowerCase() === 'out') {
      if (canvas.getZoom().toFixed(5) <= -5) {
        console.log('zoomByMouseCoords: Error: cannot zoom-out anymore');
        return;
      }
      zoomOut(canvas);
    }
    if (scrollRef) {
      scrollRef.scrollLeft((scrollRef.getScrollWidth() - size.width) / 2);
      scrollRef.scrollTop((scrollRef.getScrollHeight() - size.height) / 2);
    }
  };

  const resetZoom = (canvas) => {
    canvas.setHeight(canvas.getHeight() / canvas.getZoom());
    canvas.setWidth(canvas.getWidth() / canvas.getZoom());
    canvas.setZoom(1);

    canvas.renderAll();
  };

  return {
    zoomByMouseCoords,
    resetZoom,
    zoomIn,
    zoomOut,
  };
};
