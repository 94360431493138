import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import * as selectors from './selectors';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    setGoogleFonts: ['fonts'],
  },
  { prefix: 'APP/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  googleFonts: [],
});

/* ---------- Reducers ---------- */
const addReducer = (state, action) =>
  state.merge({
    googleFonts: action.fonts,
  });

/* ---------- Main Reducer ---------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_GOOGLE_FONTS]: addReducer,
});

export const appActions = Creators;
export const appTypes = Types;
export const appSelectors = selectors;

export default { appActions, appSelectors };
