/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';

import { DrawContext } from 'core/contexts';
import Box from 'core/ui/Box';
import { ButtonCancel, ButtonSecondary } from 'core/ui/Button';
import SelImage from 'core/ui/SelImage';
import UploadS3 from 'core/ui/UploadS3';
import TextField from 'core/ui/TextField';

const FormText = ({ current, callback }) => {
  const { saveImage } = useContext(DrawContext);
  const [showSave, setShowSave] = useState(false);
  const [upload, setUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [currentElement, setCurrentElement] = useState({});

  useEffect(() => {
    if (current) {
      setCurrentElement(() => ({
        ...current,
      }));
      setFile(null);
    } else {
      setCurrentElement({});
    }
  }, [current]);

  useEffect(() => {
    setShowSave(true);
  }, [currentElement]);

  const save = () => {
    setShowSave(false);
    setUpload(false);
    setFile(null);
    saveImage({ ...currentElement });
    setCurrentElement({});
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    if (file && currentElement.image && currentElement.image.complete === true) {
      save();
    }
  }, [currentElement.image && currentElement.image.complete]);

  const mb = 2;
  return (
    <Box mb={3}>
      <Box mb={mb}>
        <Box mb={mb}>
          <TextField
            onChange={(v) => setCurrentElement((prev) => ({ ...prev, name: v }))}
            value={currentElement.name || ''}
            label="Name"
          />
        </Box>

        {!currentElement.id && (
          <>
            <SelImage image={file} setImage={setFile} label="File Upload" />

            {file && upload && (
              <UploadS3
                fileUpload={file}
                setComplete={(v) => {
                  setCurrentElement((prev) => ({ ...prev, image: { ...prev.image, ...v } }));
                }}
              />
            )}
          </>
        )}
      </Box>

      <Box display="flex" justifyContent="center">
        {currentElement && currentElement.image && (
          <img src={currentElement.image.Location} width={200} alt="" />
        )}
      </Box>

      {(!currentElement.id || showSave) && (
        <Box mt={5} display="flex">
          <ButtonSecondary fullWidth onClick={() => (currentElement.id ? save() : setUpload(true))}>
            Save
          </ButtonSecondary>
          &nbsp;
          {!currentElement.id && (
            <ButtonCancel fullWidth onClick={() => callback()}>
              Cancel
            </ButtonCancel>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormText;
